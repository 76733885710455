import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationExtras,
} from "@angular/router";
import { GlobalComponent } from "./global/global.component";
import { Employee } from "./pages/employee-parent/employee-parent.model";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(public global: GlobalComponent, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let stateValue = state.url;
    
    const requiredPermission = next.data.permission;
    const notrequiredRouter = next.data.notrequiredrouter;
    const hasRequiredRouter = this.global.globalmenu?.filter(
      (role) => role.routerLink == requiredPermission
    )[0]?.routerLink;
    if (stateValue != "/housingstabilization") {
      this.global.contactHouseCaseTypelid = 0;
    }
    if (!(stateValue == "/employee" || stateValue == "/dashboard ")) {
      this.global.globalemployee = 0;
      this.global.globalemployeedata = new Employee();
    }
    if (stateValue != "/claims") {
      this.global.globalclaimfilter = null;
      localStorage.removeItem("ac_claimsrowSpot");
    }
    if (stateValue != "/timesheets") {
      this.global.InactiveClientTimesheets = false;
    }
    let isLoggedIn = this.global.authtoken;
    if (
      isLoggedIn != null &&
      isLoggedIn != undefined &&
      isLoggedIn != "" &&
      this.global.loginType != "JobPortalLogin"
    ) {
      if (hasRequiredRouter) {
        return true;
      } else {
        if (notrequiredRouter) {
          
        } else {
        
          let navigationExtras: NavigationExtras = {
            state: {
              PageName: requiredPermission,
            },
          };
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          this.router.navigate(["/unauthorizedusers"], navigationExtras);
        }
      }
      return true;
    } else {
      if (this.global.loginType == "JobPortalLogin") {
        this.router.navigate(["/**"]);
      } else {
        this.router.navigate(["/login"]);
      }
    }
  }
}
