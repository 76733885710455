import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/global/global.component';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent {

  router: Router;

  constructor(router: Router, public global: GlobalComponent) {
        
    this.router = router;
  }

  searchResult(): void {
    this.router.navigate(['/search']);
  }

  ngAfterViewInit() {
    document.getElementById('preloader').classList.add('hide');
  }

}
