import { Injectable, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { generalservice } from 'src/app/services/general.service';
import { Employee } from '../pages/employee-parent/employee-parent.model';
import { ClientBO } from '../pages/client-parent/client-parent.model';
import { Subject, Subscription, interval } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { SwUpdate } from '@angular/service-worker';
import * as moment from 'moment-timezone';
import { NotifyService } from '../pages/notify/notify.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class GlobalComponent implements OnInit {
  public agencyCode: string = "";
  private onDestroy$: Subject<void> = new Subject<void>();
  public globalColumn: any = [];
  loginType: string = "";
  searchEmployeeText: string = "";
  searchEmployeeStatus: String = "";
  searchClientText: string = "";
  searchClientStatus: string = ""
  reporttype: string = ""
  LovValues: any = [];
  LocationValues: any = [];
  ServiceValues: any = [];
  UserValues: any = [];
  agencyCurrentTime: string;
  ServiceDropDownValues: any = [];
  MasterServiceDropDownValues: any = [];
  ServicePayrateList: any = [];
  globalLoc: any = [];
  globalServiceDropdown: any = []
  globalServiceCodeDropdown: any = []
  isEmployeeName: string = null;
  isEmployeeNewTab: boolean = false;
  isClientNewTab: boolean = false;
  CompanyDropDownValues: any = [];
  globalClearinghouse: any = [];
  globalCommonGetGPList: any = [];
  PayorList: any = [];
  isassignboolean: boolean = false;
  isSupervisionReport: boolean = false;
  reportday: number = 0;
  public authtoken: string;
  public jobvalueid: string;
  isAcceptOnboard: boolean = false;
  isRejectOnboard: boolean = false;
  public setJobSeekerId: string;
  ClientName: string;
  Medicaid: string;
  otpId: string;
  interval: any;
  jobstatus: boolean = false;
  refreshTokenString: string;
  ClientDob: string;
  isServiceWorkersEnabled: boolean = false;
  powerbiToken: string;
  public requestId: string;
  globalclientdata: ClientBO = new ClientBO();
  globalemployee: number = 0;
  contactTypes: any = [];
  serviceType: any = [];
  globalemployeedata: Employee = new Employee();
  menu: boolean;
  fund: boolean;
  view: Boolean;
  calendarHeightdefault: string;
  calendarHeight: string;
  calendarHeight1: string;
  InactiveClientId: any = null;
  globalclaimfilter: any = null;
  InactiveClientTimesheets: boolean = false;
  InactiveClientTimesheetsFromDate: any = null;
  InactiveClientTimesheetsToDate: any = null;
  isCheckOperationDashboardRecord: boolean = false;
  globalCLaimStatus: any = null;
  isglobalFromorPreparedDate: boolean = null;
  claimsFromDate: any;
  claimsToDate: any;
  isglobal30DaysOlder: boolean = null;
  agencyData: any = {};
  globalClaimType: boolean = null;
  editAgentId: number = 0;
  refreshSubscription: Subscription;
  isViewEmployeefields: boolean = true;
  public otpuserId: any;
  public otp: any;
  contactHouseCaseTypelid: number;
  jobPortalUserName: string;
  showsingleRole: boolean = false;
  public ResendEmail: any;
  public ResendAgencycode: any;
  public employee_ID: number;
  public group_Payor_ID: any;
  public security_Level: any;
  public clientId: number;
  checkclaimProgress: boolean = null;
  checkidle: boolean = true;
  public userID: any;
  public globalClientmedicationId: number;
  public startTime: any;
  public timefrom: any;
  public driverName: any;
  public getdate: any;
  public date: any = new Date();
  public ViewAll: boolean;
  public ShowOnly: boolean;
  public isAllData: boolean;
  public LegStatusFilter: any = [];
  public DriverList: any = [];
  public Accessright: boolean;
  public tblID: any;
  public emp_ID: number;
  public leg: any;
  public userRoleDropDown: any = [];
  public deviceWidth: number;
  public city: any;
  public state: any;
  public zip: any;
  public loginUser: any;
  public globalAgencyId: any = 0;
  public globalAgencyDropDown: any = [];
  public globalICD10List: any = [];
  public logginedUserName: any;
  public userDetail: any = {};
  public agencyName: any = null;
  public agencyPhone: any = null;
  public agencyLogo: any = null;
  public loading: boolean = false;
  public roleId: string = "SUPERADMIN";
  public listId: any = 3;
  public email: string;
  public deleteErr: string;
  public ErrorBool: boolean = false;
  public globalmenu: any = []
  public userSelectedRole: string = "";
  public ngAgencyDropDown: any = [];
  public ngselectedAgency: any = "";
  public agencyCodeName: string;
  public JobportalagencyId: any = 0;
  public JobSeekerId: any;
  public jobportalEmail: string;
  public JobPortalagencyName: string;
  public ngLocationDropDown: any = [];
  public ngselectedLocation: any = "";
  public globalLocationId: any = null;
  public globalLocationDropDown: any = [];
  public globalFeatures: any = [];
  locationData: any = 0;
  public globalroleName: string;
  public isViewClientPageDis: boolean = false;
  public isJobPortalRegister: boolean = true;
  public globalTimezone: string = "";
  public globalState: string = "";
  public timeZoneLOV: any = [];
  public timeZone: string = "";
  public QpCodeInfo: string = "";
  timeInterval: Subscription;
  constructor(public update: SwUpdate, public http: HttpClient, public route: Router, public general: generalservice, private router: Router,
    public toasterService: ToastrService, public errorService: ErrorService, private datePipe: DatePipe, private modalService: NotifyService) {
    // this.decodedToken = this.JwtHelper.decodeToken(this.global.authtoken);
  }
  ngOnInit() {
  }
  //........AddJobPortaldata....................................................................................
  AddJobPortaldata(data) {
    this.authtoken = data.accessToken;
    this.JobSeekerId = data.jobSeekerId;
  }
  //.........GeneratingToken............................................................................................
  GeneratingToken(data) {
    this.authtoken = data.accessToken;
    if (data.accessToken != null) {
      this.getOverallAgencyDropDown();
    }
  }
  //agency& user Details//////
  getAgencyDetails(agencyValues) {
    this.agencyCode = agencyValues.agencyCode;
    this.globalroleName = agencyValues.roleName;
    this.logginedUserName = agencyValues.userName;
    this.agencyLogo = agencyValues.agencyLogo;
    this.agencyName = agencyValues.agencyName;
    this.agencyPhone = agencyValues.phone;
    this.userID = agencyValues.userId;
    this.globalAgencyId = agencyValues.agencyId;
    this.roleId = agencyValues.roleId;
    this.globalTimezone = agencyValues.timeZone;
  
    this.timeZone = this.timeZoneLOV.filter(s => s.lovCode === this.globalTimezone)[0]?.lovName
    console.log(this.timeZone)
    if (this.userID != 0) this.globalState = agencyValues.agencyState;
    if (this.agencyPhone != null) {
      this.agencyPhone = this.general.converPhoneGoogleLib(this.agencyPhone);
    }
  }
  //........................getOverallAgencyDropDown...................................................................
  getOverallAgencyDropDown() {
    let url = "api/Agency/OverallAgency";
    this.http.get(url).pipe().subscribe((data) => {
      this.globalAgencyDropDown = data;
      localStorage.setItem("ac_agencyNames", JSON.stringify(data));
    })
  }
  //ac_agencyName
  AddAgencyDropdown(data) {
    this.globalAgencyDropDown = data;
    this.ngAgencyDropDown = this.globalAgencyDropDown;
    this.ngAgencyDropDown.forEach(a => a.id = a.id.toString());
    this.ngselectedAgency = this.globalAgencyId == 0 ? '' : this.globalAgencyId?.toString();
  }
  //.........AddMenuItems.......................................................................................
  AddMenuItems(data) {
    let menu: any = []
    let i: number = 1;
    if (data == null) {
      return;
    }
    data.forEach(element => {
      let temp: any = {}
      temp.id = i;
      temp.title = element.title;
      temp.routerLink = element.link;
      temp.href = null;
      temp.icon = element.icon;
      temp.target = null;
      temp.parentId = 0;
      if (element.children != null && element.children.length > 0) {
        let j: number = i + 1;
        element.children.forEach(element => {
          let submenu: any = {}
          submenu.id = j;
          submenu.title = element.title;
          submenu.routerLink = element.link;
          submenu.href = null;
          submenu.icon = element.icon;
          submenu.target = null;
          submenu.hasSubMenu = false;
          submenu.parentId = i;
          menu.push(submenu);
          j++;
        });
        i = j - 1;
        temp.hasSubMenu = true;
      }
      else {
        temp.hasSubMenu = false;
      }
      menu.push(temp);
      i++;
    });
    this.globalmenu = menu;
    localStorage.setItem("ac_menulist", JSON.stringify(this.globalmenu));
  }
  //..........Addagencydata.....................................................................................
  Addagencydata(data) {
    this.agencyLogo = data.agencyLogo;
    this.agencyName = data.agencyName;
    this.agencyPhone = data.phone;
    if (this.agencyPhone != null) {
      this.agencyPhone = this.general.converPhoneGoogleLib(this.agencyPhone);
    }
    this.globalAgencyId = data.agencyId;
    this.globalTimezone = data.timeZone;
    this.globalState = data.agencyState;
    this.timeZone = this.timeZoneLOV.filter(s => s.lovCode === this.globalTimezone)[0]?.lovName
    console.log(this.timeZone)
    // moment.tz.setDefault(data.timeZone);
    // setTimeout(() => {
    //   this.checkZoneConfirmation();
    // }, 3000);
  }
  //...........onRefreshGetData.................................................................................
  onRefreshGetData(selectedAgencyId) {
    var agency_Id: any = 0;
    let url = "api/Agency/OverallAgencyInfo?";
    let myparams = new URLSearchParams();
    if (selectedAgencyId == '') {
      myparams.append("agencyId", agency_Id);
    }
    else {
      myparams.append("agencyId", selectedAgencyId);
    }
    this.http.get(url + myparams).subscribe((data: any) => {

      if (data != null) {
        this.agencyLogo = data.agencyLogo;
        this.agencyName = data.agencyName;
        if (data.phone != null) {
          data.phone = this.general.converPhoneGoogleLib(data.phone);
          this.agencyPhone = this.general.converPhoneGoogleLib(data.phone);
        }
        else {
          this.agencyPhone = null;
        }
        this.globalTimezone = data.timeZone;
        this.timeZone = this.timeZoneLOV.filter(s => s.lovCode === this.globalTimezone)[0]?.lovName
        console.log(this.timeZone)
        // moment.tz.setDefault(data.timeZone)
        this.globalState = data.agencyState;

        data.agencyId = selectedAgencyId;
        this.globalAgencyId = selectedAgencyId;
        this.getUserLocationDropDown(this.userID);
        localStorage.setItem("ac_globalAgencydetails", JSON.stringify(data));
        this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        this.route.onSameUrlNavigation = 'reload';
        this.route.navigate(['/dashboard']);
      }
      else {
        this.agencyLogo = null;
        this.agencyName = null;
        this.agencyPhone = null;
        this.globalAgencyId = 0;
        this.globalTimezone = "";
        this.timeZone = "";
        this.route.navigateByUrl('/agency');
        this.route.routeReuseStrategy.shouldReuseRoute = () => false;
        this.route.onSameUrlNavigation = 'reload';
        this.route.navigate(['/agency']);
      }
    }
    )
  }

  //.......validation for alphabets and Number..................................................................
  isalpha(c) {
    return (((c >= 'a') && (c <= 'z')) || ((c >= 'A') && (c <= 'Z')));
  }
  isdigit(c) {
    return ((c >= '0') && (c <= '9'));
  }
  isalnum(c) {
    return (this.isalpha(c) || this.isdigit(c));
  }
  //.........onRefreshGetDataLocation...........................................................................
  onRefreshGetDataLocation(selectedLocationId) {
    this.globalLocationId = selectedLocationId;
    localStorage.setItem("ac_locationId", JSON.stringify(selectedLocationId));
    this.reloadComponent();
  }
  //..........reloadComponent...................................................................................
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  ///..............................getUserLocationDropDown...........................................................
  getUserLocationDropDown(id) {
    let url = "api/User/GetuseLocationdropdown?";
    let myParams = new URLSearchParams();
    myParams.append("userid", id);
    myParams.append("Code", "LOCATION_STATUS");
    myParams.append("agencyId", this.globalAgencyId);
    this.http.get(url + myParams).subscribe((data) => {
      this.globalLocationDropDown = [];
      this.globalLocationDropDown = data;
      this.ngLocationDropDown = this.globalLocationDropDown;
      this.ngLocationDropDown.forEach(a => a.Key = a.Key.toString());
      if (this.userID == 0) {
        this.globalLocationId = null;
        this.ngselectedLocation = null;
        localStorage.removeItem("ac_locationId");
      }
    },
      err => {
      })
  }

  //............getFeaturesByName...............................................................................
  getFeaturesByName(Name) {
    var enable
    this.globalFeatures.forEach(element => {
      if (element.featureName == Name) {
        enable = element.enabled;
      }
    });
    return enable;
  }
  //........checkforJobPortalLogin..............................................................................
  checkforJobPortalLogin() {
    let JobPortalData = localStorage.getItem("ac_login");
    if (JobPortalData != null) {
      this.router.navigateByUrl("/**");
    }
  }
  agencyList() {
    this.agencyData = JSON.parse(localStorage.getItem("ac_agencydetails"));
    if (this.agencyData.userId == null || this.agencyData.userId == undefined || this.agencyData.userId == 0) {
      this.roleId = this.agencyData.roleId;
    }
    else {
      this.roleId = localStorage.getItem("ac_role");
    }
  }
  //.......AddJobseekerList.....................................................................................
  AddJobseekerList(data) {
    this.jobvalueid = data.id;
  }
  //.......getJobSeekerdata.....................................................................................
  getJobSeekerdata(data) {
    this.setJobSeekerId = data.jobSeekerId
  }
  // ..................................RefreshT0ken.........................................................................
  refreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      let url = "api/Auth/refreshToken";

      let refreshpayload = {
        refreshToken: this.refreshTokenString,
        accessToken: this.authtoken
      }
      this.http.post(url, refreshpayload).subscribe((response: any) => {
        this.authtoken = response.accessToken;
        this.refreshTokenString = response.refreshToken;
        this.isassignboolean = true;
        localStorage.setItem("ac_login", JSON.stringify(response));
        localStorage.setItem("ac_refreshtoken", JSON.stringify(response.refreshToken));
        resolve(response)
      },
        (err: HttpErrorResponse) => {
          this.toasterService.error("Your Session Got Expired, Please Login", 'Session Expired');
          this.clearLocalStorage();
          localStorage.clear();
          // Note:Because of Column Reordering in Timesheet Verification and claims ,LocalStorage.Clear() commented.
          this.agencyLogo = null;
          this.agencyName = null;
          this.agencyPhone = null;
          this.globalAgencyId = '';
          this.globalLocationId = '';
          this.ngselectedLocation = '';
          this.authtoken = null;
          this.ngLocationDropDown = [];
          this.userRoleDropDown = []
          this.userSelectedRole = ""
          this.ngselectedAgency = "";
          this.loginType = "";
          this.LovValues = [];
          this.LocationValues = [];
          this.ServiceValues = [];
          this.UserValues = [];
          this.refreshTokenString = null;
          this.ServiceDropDownValues = [];
          this.MasterServiceDropDownValues = [];
          this.ServicePayrateList = [];
          this.CompanyDropDownValues = [];
          this.globalLoc = [];
          this.PayorList = [];
          this.globalClearinghouse = [];
          this.globalCommonGetGPList = [];
          this.router.navigateByUrl("/login");
          reject(err);
        });
    });
  }
  scheduleRefresh() {
    this.refreshSubscription = interval(1000 * 60 * 25).subscribe(() => {
      this.refreshToken().catch(() => {
        this.refreshSubscription.unsubscribe();
      });
    });
  }
  // data format
  getDateTimeString(date: any): string {
    var tzoffset = (new Date(date)).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(new Date(date).getTime() - tzoffset)).toISOString().slice(0, -1);
  }
  //........................................................clearLocalStorage.........................................
  clearLocalStorage() {
    localStorage.removeItem("ac_menulist");
    localStorage.removeItem("ac_sidebars");
    localStorage.removeItem("ac_agencyNames");
    localStorage.removeItem("ac_globalAgencydetails");
    localStorage.removeItem("ac_role");
    localStorage.removeItem("ac_agencydetails");
    localStorage.removeItem("ac_locationId");
    localStorage.removeItem("ac_login");
    localStorage.removeItem("ac_roleName");
  }
  //........................................................toFeet.........................................
  toFeet(height) {
    var realFeet = ((height) / 12);
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + " " + "ft" + " " + inches + " " + 'in';
  }
  //..............................................getLocation........................................................
  getLocation(id) {
    let url = "api/User/GetuseLocationdropdown?";
    let myParams = new URLSearchParams();
    myParams.append("userid", id);
    myParams.append("Code", "LOCATION_STATUS");
    myParams.append("agencyId", this.globalAgencyId);
    this.http.get(url + myParams).pipe().subscribe((data) => {
      this.globalLocationDropDown = [];
      this.globalLocationDropDown = data;
      this.ngLocationDropDown = this.globalLocationDropDown;
      this.ngLocationDropDown.forEach(a => a.Key = a.Key.toString());
      this.locationData = JSON.parse(localStorage.getItem("ac_locationId"));
      this.globalLocationId = this.locationData;

    },
      err => {
      })
  }
  //........................................checkClaimCreationProgress............................................
  checkClaimCreationProgress() {
    let progressId = localStorage.getItem("ac_progressId");
    if (progressId != null && progressId != undefined && progressId != '') {
      let url = "api/BillingClaim/checkClaimCreationProgress?";
      let myParams = new URLSearchParams();
      myParams.append("progressId", progressId)
      this.http.get(url + myParams).pipe().subscribe((data: any) => {
        this.checkclaimProgress = data;
        this.checkidle = false;
        if (data == true) {

          setTimeout(() => {
            this.checkClaimCreationProgress();
          }, 5000);

        }
        else {

          clearInterval(this.interval);
          localStorage.removeItem("ac_progressId");
        }
      },
      )
    }
  }
  //..........................scheduleClaimProgress........................................................
  scheduleClaimProgress() {
    this.interval = setTimeout(() => {
      this.checkClaimCreationProgress();
    }, 1000);


  }
  //..........................checkForAppUpdateAvailable........................................................
  checkForAppUpdateAvailable() {
    this.update.available.subscribe(event =>
      this.versionCheck());
  }
  //..........................versionCheck........................................................
  versionCheck() {
    console.log("version is available")
    this.isServiceWorkersEnabled = true;
  }

  //..........................tConvert........................................................
  tConvert(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(''); // return adjusted time or original string
  }
  //..........................preventforSpace........................................................
  preventforSpace(event) {

    if (event.keyCode == 32) {
      event.preventDefault();
    }
  }
  //..........................getjobStatus........................................................
  getjobStatus() {
    let url = "api/JobSeekerRequest/getJobseekerRequestStatus?";
    let myParams = new URLSearchParams();
    myParams.append("JobSeekerId", this.JobSeekerId);
    this.http.get(url + myParams).subscribe(
      (response: any) => {
        this.jobstatus = response;
      },
      (err: HttpErrorResponse) => { }
    );
  }
  getDateTimeUTC(date: any): string {
    let datess: any = this.datePipe.transform(new Date(date), 'yyyy-MM-dd HH:mm:ss')
    return (new Date(datess)).toISOString();
  }

  getDateTimeWithZone(time){
    return moment(time).utc().toISOString(); // Return '2024-07-31T07:00:00.000Z'
      // return moment.tz(time,"MM-DD-YYYY HH:mm:ss", this.globalTimezone).toISOString();
  }
  getDateWithUTCFormat(time){
    return moment.utc(time).startOf('day').toISOString();  // Return '2024-07-01T00:00:00.000Z'
  }
  getDateWithoutZone(Date){
      return moment.utc(Date).toISOString();
  }
  getDateWithStartTime(Date):string{
    return moment(Date).startOf('d').toISOString();
  }

  getDateWithEndTime(Date): string {
    return moment(Date).endOf('d').toISOString();
  }
  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}
  // checkZoneConfirmation(){
  //   if(this.globalTimezone != '' && this.globalTimezone != null && this.globalTimezone != undefined){
  //     const Zone = moment.tz(moment.tz.guess()).format('Z');
  //     const agencyZone = moment.tz(this.globalTimezone).format('Z');
  //     !(Zone === agencyZone) ? this.modalService.openModal() : null;
  //   }
  // }

  getWebsiteUserDetails() {
    var url = "api/Auth/getWebsiteUserDetails?";
    this.http.get(url).subscribe((data: any) => {
      this.globalState = data.agencyState;
    },
      (err: any) => {
      })
  }

  //----------------------------Get TimeZone Dropdown-----------------------------------//

  GetTimeZoneDropdown() {
    let params = new URLSearchParams();
    params.append("Code", "TIMEZONE");
    let url = "api/LOV/getLovByCode?"
    this.http.get(url + params).subscribe((data: any) => {
      this.timeZoneLOV = data;
      if (this.globalTimezone != null && this.globalTimezone != "" && this.globalTimezone != undefined) {
        this.timeZone = this.timeZoneLOV.filter(s => s.lovCode === this.globalTimezone)[0]?.lovName
        console.log(this.timeZone)
      }
    },
      (err: any) => {

      })
  }

  getEmployeeType() {
    let params = new URLSearchParams();
    params.append("Code", "EMPLOYEETYPE");
    let url = "api/LOV/getLovByCode?"
    this.http.get(url + params).subscribe((data: any) => {
      this.QpCodeInfo = data.length ? data.filter(c => c.lovCode.toLowerCase() === "qpemployee")[0]?.id : ""
    },
      (err: any) => {

      })
  }
  
  getAgencySpecificTimeZone(Date) {
    const convertToAgencyTimeZone = moment.tz(Date, "UTC");
    return (convertToAgencyTimeZone.tz(this.globalTimezone).format("MM/DD/YYYY hh:mm:ss a"))
  }
  getAgencySpecificStartDatewithTime(Date) {
    var formatDate=this.datePipe.transform(Date,'yyyy-MM-dd')
    const SdTF = moment.tz(formatDate, this.globalTimezone);
    return SdTF.startOf('d').utc().format();
  }
  getAgencySpecificEndDatewithTime(Date) {
    var formatDate=this.datePipe.transform(Date,'yyyy-MM-dd')
    const EDTF = moment.tz(formatDate, this.globalTimezone);
    return EDTF.endOf('d').utc().format();
  }
  getDateTimeWithUTCFormat(time: any): string {
    return moment.utc(time).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  }

  getsameTimeInDifferentZone(date: any) {
    return moment
      .tz(moment(date).format('YYYY-MM-DD HH:mm:ss'), this.globalTimezone)
      .toDate();
  }
  
 
  emailRegexValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const email = control.value;
      // Check if the email contains '@' symbol
      if (email && !email.includes('@')) {
        return { invalidEmail: true };
      }
      return null;
    };
  }
  
  
}
