import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalComponent } from 'src/app/global/global.component';
import { UserMenuService } from './user-menu.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  islogoutOfAllSessions: boolean = false;
  constructor(public global: GlobalComponent, private router: Router, public Usermenuservice: UserMenuService, public toasterService: ToastrService) { }

  private intervalId: any;
  ngOnInit() {
    console.log(this.global.globalTimezone)
    if (this.global.globalTimezone != null && this.global.globalTimezone != undefined && this.global.globalTimezone != '') {
      this.intervalId = setInterval(() => {
        this.updateTime();
      }, 1000);
    }
    else {
      clearInterval(this.intervalId);
    }
    let roleName = JSON.parse(localStorage.getItem("ac_roleName"));

    if (roleName != null) {
      let roles = roleName.split("?")[0];
      this.global.globalroleName = roles;
    }
  }
  private updateTime(): void {
    const timeZone = this.global.globalTimezone;
    if (timeZone != null && timeZone != undefined && timeZone != '') {
      const now = new Date();
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        timeZone,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(now);
      this.global.agencyCurrentTime = formattedDate
    }
  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


  clearBoolean() {
    this.islogoutOfAllSessions = false;
  }
  routeToActiveSession() {
    this.islogoutOfAllSessions = false;
    this.router.navigateByUrl("/active-session");
  }
  logoutSession() {
    if (this.islogoutOfAllSessions == true) {
      this.islogoutOfAllSessions = true;
    }
    else {
      this.islogoutOfAllSessions = false;
    }
    this.Usermenuservice.logoutSession(this.islogoutOfAllSessions).pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
      this.toasterService.success('Logout Successful');
      this.logout();
    },
      (err: HttpErrorResponse) => {
        this.logout();
      })
  }

  logout() {

    this.global.authtoken = null;
    this.global.refreshSubscription.unsubscribe();
    localStorage.removeItem("ac_menulist");
    localStorage.removeItem("ac_sidebars");
    localStorage.removeItem("ac_agencyNames");
    localStorage.removeItem("ac_globalAgencydetails");
    localStorage.removeItem("ac_role");
    localStorage.removeItem("ac_agencydetails");
    localStorage.removeItem("ac_locationId");
    localStorage.removeItem("ac_login");
    localStorage.removeItem("ac_roleName");
    localStorage.removeItem("ac_EmployeeNewTabFunctionality");
    localStorage.removeItem("ac_ClientNewTabFunctionality");
    // localStorage.clear();
    // Note:Because of Column Reordering in Timesheet Verification and claims ,LocalStorage.Clear() commented.
    this.global.agencyLogo = null;
    this.global.agencyName = null;
    this.global.agencyPhone = null;
    this.global.globalAgencyId = '';
    this.global.globalLocationId = '';
    this.global.ngselectedLocation = '';
    this.global.ngLocationDropDown = [];
    this.global.userRoleDropDown = []
    this.global.userSelectedRole = ""
    this.global.loginType = "";
    this.global.ngselectedAgency = "";
    this.global.LovValues = [];
    this.global.LocationValues = [];
    this.global.ServiceValues = [];
    this.global.UserValues = [];
    this.global.ServiceDropDownValues = [];
    this.global.MasterServiceDropDownValues = [];
    this.global.CompanyDropDownValues = [];
    this.global.ServicePayrateList = [];
    this.global.globalLoc = [];
    this.global.globalClearinghouse = [];
    this.global.globalCommonGetGPList = [];
    this.global.globalTimezone = "";
    this.global.timeZone = "";
    this.global.timeZoneLOV = [];
    this.global.globalState = "";
    this.router.navigateByUrl("/login");
  }
}
