import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifyComponent } from './notify.component';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  private isModalOpen = false;
  private modalRef
  constructor(private modalService: NgbModal) { }

  openModal() {
    if (!this.isModalOpen) {
       this.modalRef = this.modalService.open(NotifyComponent, { centered: true,backdrop: 'static' });
      this.modalRef.result.then(
        () => {
          this.isModalOpen = false;
        },
        () => {
          this.isModalOpen = false;
        }
      );

      this.isModalOpen = true;
    }
  }

  closeModal() {
    this.isModalOpen = false;
    this.modalRef.close();
  }


}
