<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed"
  [class.logo-visible]="showInfoContent">
  <div class="d-flex flex-column w-100">
    <div id="info-content" class="panel-collapse collapse">
      <a class="close" data-toggle="collapse" href="#info-content" (click)="showInfoContent = !showInfoContent"
        [class.show]="showInfoContent">
        <span aria-hidden="true">&times;</span>
      </a>
    </div>
    <div class="d-flex justify-content-start align-items-center top-navbar">
      <span class="navbar-text menu-icon transition  mr-1 mt-1">
        <i class="fa fa-bars " (click)="settings.theme.showMenu = !settings.theme.showMenu" style="font-size:20px;color:black"></i>
      </span>
      
      <a class="logo d-flex align-items-center" (click)="closeSubMenus()">
        <b _ngcontent-skc-c4="">
          <span _ngcontent-skc-c4="" style="color:#0D47A1;font-size: 23px;    font-family: unset">All</span><span _ngcontent-skc-c4=""
            style="color:#689F38;font-size: 23px;    font-family: unset">Care </span><span _ngcontent-skc-c4=""
            style="color:#689F38;font-size: 23px;    font-family: unset">Software</span></b>
      </a>
      <!-- image -->
      <form class=" d-none d-md-inline-block ml-3">
        <img src="/assets/img/logo.png" alt="Avatar" class="avatar" *ngIf="global.agencyLogo==null">
        <img [src]="global.agencyLogo" alt="Avatar" class="avatar" *ngIf="global.agencyLogo!=null">
      </form>
      <!-- agencyname -->
      <div class="d-none d-md-inline-block ml-3">
        <label *ngIf="global.agencyName!=null" style="width: 129px;">{{global.agencyName}} </label>
      </div>
      <!-- Agency -->
      <form class="app-search d-none d-md-inline-block ml-3 col-lg-3  col-sm-4 col-md-3"
        *ngIf="global.globalAgencyDropDown.length != 0 &&  this.global.agencyCode=='SUPERADMIN'">
        <label>Agency</label>
        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="global.ngselectedAgency" (change)="refreshPage()"
        id="SelectAgency"
        style=" color: black;" [clearable]="true" [searchable]="true" [items]="global.ngAgencyDropDown"
        bindLabel="agencyName" bindValue="id">
      </ng-select>
      </form>
      <!-- Role -->
      <form class=" d-none d-md-inline-block ml-3" *ngIf="global.userRoleDropDown.length > 1">
        <label>Role</label>
        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="global.userSelectedRole" (change)="changerole()"
        id="SelectRole"  class="form-control" style=" color: black;">
          <option></option>
          <option [value]=ag.key *ngFor="let ag of global.userRoleDropDown">{{ag.value}}</option>
        </select>
      </form>
      <form class=" d-none d-md-inline-block ml-3 mt-4" *ngIf="global.isServiceWorkersEnabled">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="animate-charcter" (click)="activateToCurrentVersion()" title="Click to Refresh"> New Version Available</h3>
            </div>
          </div>
        </div>
      </form>
      <form class=" d-none d-lg-block ml-auto  ">
        <label>Location</label>
        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="global.ngselectedLocation" class="form-control"
        id="SelectLocation"  (change)="refreshPageLocation()" style=" color: black;display: block;">
          <option></option>
          <option [value]="ag.Key" *ngFor="let ag of global.ngLocationDropDown">{{ag.Value}}</option>
        </select>
      </form>
      <span class="d-none d-sm-inline-block ml-auto mt-4">
        <app-user-menu ></app-user-menu>
        <app-fullscreen></app-fullscreen>
      </span>
      <!--  -->
      <span class="d-none d-sm-inline-block mt-4" >
        <app-claim-creation></app-claim-creation>
      </span>
      <div class="mt-4">
        <span class="pl-2 pr-2 mt-4">
        </span>
      </div>
    </div>
    <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">
      <app-horizontal-menu *ngIf="showHorizontalMenu" [menuItems]="menuItems"></app-horizontal-menu>
      <app-vertical-menu *ngIf="!showHorizontalMenu" [menuItems]="menuItems"></app-vertical-menu>
    </div>
  </div>
</nav>



