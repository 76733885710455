import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { Router } from '@angular/router';
import { GlobalComponent } from "../../../global/global.component";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SwUpdate } from '@angular/service-worker';
@Component({

  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
  animations: [
    trigger('showInfo', [
      state('1', style({ transform: 'rotate(180deg)' })),
      state('0', style({ transform: 'rotate(0deg)' })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  userSelectedRole: string = "";
  agencys: any = {};
  public showHorizontalMenu: boolean = true;
  public showInfoContent: boolean = false;
  public settings: Settings;
  public menuItems: Array<any>;
  agencydetails: any = {};
  constructor(public updates: SwUpdate, public appSettings: AppSettings, public menuService: MenuService, private router: Router, public global: GlobalComponent,
    public http: HttpClient, public toasterService: ToastrService) {
    this.settings = this.appSettings.settings;

  }


  ngOnInit() {

    //   // agencyDetails
    this.agencys = JSON.parse(localStorage.getItem("ac_agencyNames"));

    // role
    this.userSelectedRole = localStorage.getItem("ac_role")
    this.userSelectedRole = this.global.roleId;
    // agencyDropDownNames
    let agNames = JSON.parse(localStorage.getItem("ac_agencyNames"));
    if (agNames != null) {
      this.global.AddAgencyDropdown(agNames);
    }

    if (window.innerWidth <= 768) {
      this.showHorizontalMenu = false;
    }
  }
  //..............................closeSubMenus...........................................................
  public closeSubMenus() {
    let menu = document.querySelector("#menu0");
    if (menu) {
      for (let i = 0; i < menu.children.length; i++) {
        let child = menu.children[i].children[1];
        if (child) {
          if (child.classList.contains('show')) {
            child.classList.remove('show');
            menu.children[i].children[0].classList.add('collapsed');
          }
        }
      }
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.showHorizontalMenu = false;
    }
    else {
      this.showHorizontalMenu = true;
    }
  }
  //................................refreshPage........................................................................
  refreshPage() {
    this.global.LovValues = [];
    this.global.LocationValues = [];
    this.global.ServiceValues = [];
    this.global.UserValues = [];
    this.global.ServiceDropDownValues = [];
    this.global.MasterServiceDropDownValues = [];
    this.global.CompanyDropDownValues = [];
    this.global.ServicePayrateList = [];
    this.global.globalLoc = [];
    this.global.globalClearinghouse = [];
    this.global.globalCommonGetGPList = [];
    this.global.globalTimezone = "";
    this.global.globalAgencyId = parseInt(this.global.ngselectedAgency || 0);
    this.global.onRefreshGetData(this.global.globalAgencyId);
    this.getSideMenus();

  }
  //....................................refreshPageLocation..............................................................
  refreshPageLocation() {
    this.global.globalLocationId = parseInt(this.global.ngselectedLocation || 0);
    this.global.onRefreshGetDataLocation(this.global.globalLocationId);
  }
  //........................................................changerole..................................................

  changerole() {
    let url = "api/Auth/changeRole?";
    let params = new URLSearchParams();
    params.append("roleToChange", this.global.userSelectedRole);
    this.http.get(url + params).subscribe((data: any) => {
      this.global.authtoken = data.accessToken;
      let refreshData = JSON.parse(localStorage.getItem("ac_refreshtoken"));
      this.global.refreshTokenString = refreshData;
      localStorage.setItem("ac_roleName", JSON.stringify(this.global.userSelectedRole))
      this.global.GeneratingToken(data);
      localStorage.setItem("ac_login", JSON.stringify(data));
      if (data.accessToken != null) {
        this.getSideMenus();
        this.global.reloadComponent();
      }
    },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error, 'Error'), 8000;
        this.global.userSelectedRole = this.global.roleId.toString();
      });



  }
  //........................................................getSideMenus.......................................................
  getSideMenus() {
    var url = "api/Auth/getSideMenus?";
    this.http.get(url).subscribe((data: any) => {
      let role = this.global.userSelectedRole != null ? this.global.userSelectedRole.toLocaleUpperCase() : "";
      let splittingRole = role?.split('?')[0];
      if (splittingRole == "SYSTEM ADMINISTRATOR") {
        let settingRole =
        {
          "title": "Settings",
          "icon": "cog",
          "link": "/setting",
          "children": null
        }
        data.push(settingRole)
      }
      this.setMenudata(data);
      localStorage.setItem("ac_sidebars", JSON.stringify(data));
    },
      (err: any) => {
        if (err) {

          this.toasterService.error(err, 'Error'), 8000;
        }
      })
  }
  //..........................................................setMenudata.........................................................
  setMenudata(menus) {
    let menu: any = []
    let i: number = 1;
    menus.forEach(element => {
      let temp: any = {}
      temp.id = i;
      temp.title = element.title;
      temp.routerLink = element.link;
      temp.href = null;
      temp.icon = element.icon;
      temp.target = null;
      temp.parentId = 0;
      if (element.children != null && element.children.length > 0) {
        let j: number = i + 1;
        element.children.forEach(element => {
          let submenu: any = {}
          submenu.id = j;
          submenu.title = element.title;
          submenu.routerLink = element.link;
          submenu.href = null;
          submenu.icon = element.icon;
          submenu.target = null;
          submenu.hasSubMenu = false;
          submenu.parentId = i;
          menu.push(submenu);
          j++;
        });
        i = j - 1;
        temp.hasSubMenu = true;
      }
      else {
        temp.hasSubMenu = false;
      }
      menu.push(temp);
      i++;
    });
    this.global.globalmenu = menu;
    localStorage.setItem("ac_menulist", JSON.stringify(this.global.globalmenu));

    this.router.navigateByUrl("/dashboard");



    if (this.global.userID != 0 && this.global.userID != null && this.global.userID != undefined) {
      localStorage.setItem("ac_role", this.global.userSelectedRole);
      this.global.roleId = this.global.userSelectedRole;

    }

    let agNames = JSON.parse(localStorage.getItem("ac_agencyNames"));
    if (agNames != null) {
      this.global.AddAgencyDropdown(agNames);
    }
  }
  //................................................activateToCurrentVersion.............................................
  activateToCurrentVersion() {
    this.updates.activateUpdate().then(() => window.location.reload());
    this.updates.activated.subscribe((event) => {

    });
  }
}

