import { EDI271AAADetailBO, EDI271LoopDetailBO } from "../eligibility270/eligibility270.model";

export class HomemakingCarePlanBO {
  id: number;
  clientId: number;
  agencyId: number | null;
  serviceId: number | null;
  clientName: string;
  clientPhone: string;
  clientAddress: string;
  clientState: string;
  clientCity: string;
  clientCounty: string;
  clientZipcode: string;
  clientEmail: string;
  clientemergencyContactName: string;
  clientemergencyContactPhone: string;
  agencyName: string;
  agencyStreet: string;
  agencyCity: string;
  agencyState: string;
  agencyZipCode: string;
  agencyPhone: string;
  agencyEmail: string;
  agencyFax: string;
  mealPreparation: string;
  errands: string;
  companionship: string;
  notes: string;
  qpName: string;
  qpPhone: string;
  caseName: string;
  casePhone: string;
  completedDate: string;
  isQp: boolean | null;
  isCaseManager: boolean | null;
  qpCompletedDate: string | null;
  caseManagerCompletedDate: string | null;
  date: string;
  qpEmployeeID: number | null;
  pOCConsultation: string;
  pOCDevelopedby: string;
  pOCDevelopedDate: string | null;
  hMKOrientedClientBy: string;
  hMKOrientedDate: string | null;
  hMKCoordinatorSign: string;
  clientSign: string;
  responsiblePartySign: string;
  homeMakerSign: string;
  hMKCoordinatorSignUrl: string;
  clientSignUrl: string;
  responsiblePartySignUrl: string;
  homeMakerSignUrl: string;
  hMKCorrdinatorSignDate: string | null;
  clientSignDate: string | null;
  responsiblePartySignDate: string | null;
  homeMakerSignDate: string | null;
  serviceactivity: homemakingCarePlanServiceActivitiesBO[];
  height: number | null;
  weight: number | null;
}

export class ClientBO {
  id: number;
  name: string | null;
  agencyId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  genderLid: number;
  qpname: string;
  dOB: string;
  age: number | null;
  weight: number | null;
  height: number | null;
  aKA: string;
  medicaid: string;
  medicare: string;
  street: string;
  exclude: boolean
  apt: string;
  phone1: string;
  pmap: string;
  phone2: string;
  county: string;
  state: string;
  city: string;
  zipCode: string;
  sSN: string;
  email: string;
  statusLid: number;
  status_Name: string;
  userName: string;
  loginUserId: string | null;
  requiredEVV: boolean | null = false;
  password: string;
  locationStatusLid: number | null;
  pClientId: number | null;
  lastEligibility: string;
  internalId: string;
  billto_Name: string;
  billto_Street: string;
  billto_City: string;
  billto_State: string;
  billto_Zipcode: string;
  billto_Phone: string;
  billto_Email: string; 
  billto_APT: string;
  insuranceNo: string;
  assessmentRatingLid: number;
  assessmentRatingName: string;
  pcaTypeLid: number;
  pcaTypeName: string;
  typeofWaiverLid: number;
  typeofWaiverName: string;
  lastSupervisionVist: string;
  completedon: string;
  expiredon: string;
  clientTypeLid: number | null;
  priorityStatus: number | null;
  generalNote: string;
  version: string;
  alter_Street: string;
  alter_City: string;
  alter_State: string;
  alter_Zipcode: string;
  alter_Phone: string;
  alter_Email: string;
  secondaryApartment:string;
  isMonitor:boolean=false;
  housingTypeLid:number;
  isHomelessClient: boolean=false;
  dob:string;

}

export class ClientReturnBO {
  clientId: number;
  version: string;
  errorList: string[];
}

export class EditDetailsClient {
  isView: boolean = false;
  isEdit: boolean = false;
  isEditClient: boolean = false;
  clientId: number = 0;
  iscounsumer: boolean = false;
  isComprehensiveNew: boolean = false;
  isPreviousRecordId:number=0;
  isEditbasicPlan: boolean = false;
  isaid: boolean = false;
  isaidandConsumer: boolean = false;
  consumerId: number = 0;
  basicCareId: number = 0;
  homeCareId: number = 0;
  isEditHomePlan: boolean = false;
  clientData: ClientBO = new ClientBO();
  ClientfilterDetails: getClient = new getClient();
  isclientfilterEnable: boolean = false;
  addpreviousdata: any;
  isconsumeraddpreviousdata: boolean = false;
  iclsCareId: number = 0;
  isEditiclsPlan: boolean = false;

  isHomeMakingCarePlanAdvanced: boolean | null;
  isCheckClientView: boolean | null;
  ischeckSupervision: boolean | null;
  isSuperVision: boolean;
  UCareId: number = 0;
  isEditUCarePlan: boolean = false;
  UCareList: any = [];
  HCareDataList: any = [];
  HealthPartnersReassessmentId: number = 0;
  isCarePlanDelete:boolean=false;
  isEditAuthPlans: boolean = false;
  isForms: boolean = false;
  isPage:string="";
  FormTemplateId: number = 0;
}

export class EditSOCDetails {
  isView: boolean = false;
  isEdit: boolean = false;
  isEditSOC: boolean = false;
  clientId: number = 0;
  type = ""
  clientSOCData: StartOfCareBO = new StartOfCareBO();
}

export class editcertificate {
  isView: boolean = false;
  isEdit: boolean = false;

  clientId: number = 0;
  type = ""
  certificateId: number = 0;
  clientcertifcateData: ClientCertificationBO = new ClientCertificationBO();
}
export class ClientAuthorizationBO {
  public id: number = 0;
  public clientId: number;
  public startDate: string;
  public endDate: string;
  public companyId: number;
  public groupPayorId: number;
  public insuranceNo: number;
  public serviceAgreementNo: string;
  public groupPayorServiceId: number;
  public serviceDescription: string;
  public totalUnits: number;
  public unitsUsed: number;
  public unitsRemaining: number;
  public totalUnitsperday: string;
  public billingvalue: number;
  public netRate: number = null;
  public ucRate: number = null;
  public previouslyUsedUnit: number = null;
  public disableAuthorization: boolean
  public DisableAuthorization: boolean

  public authLimitType: string;
  public excludeAuth: string;
  masterserviceId: number;
}
export class EditDetailsAuthorization {
  isView: boolean = false;
  isEdit: boolean = false;
  isEditAuthorization: boolean = false;
  AuthorizationId: number = 0;
  AuthorizationData: ClientAuthorizationBO = new ClientAuthorizationBO();
  ClientId: number = 0;
  type: string = '';
  // state:string;
}

export class returnClientAuthorization {
  id: number;
  clientId: number;
  startDate: string;
  endDate: string;
  companyId: number | null;
  groupPayorId: number | null;
  insuranceNo: string;
  serviceAgreementNo: string;
  groupPayorServiceId: number;
  serviceDescription: string;
  totalUnits: number;
  unitsUsed: number | null;
  unitsRemaining: number | null;
  companyName: string;
  payorName: string;
  serviceCode: string;
  serviceId: number;

}

export class ClientSOC {
  public id: number = 0;
  public clientId: number;
  public startDate: Date;
  public companyId: number;
  public dischargeLid: number;
  public dischargeDate: Date;
  public dischargeCodeDetailId: number;
  public description: string;
}
export class StartOfCareBO {
  id: number = 0;
  clientId: number;
  startDate: string;
  companyId: number | null;
  dischargeLid: number | null;
  dischargeDate: string | null;
  dischargeCodeDetailId: number | null;
  description: string;
  dischargeCode: string;
  dischargeStatus: string;
  companyName: string;
}

export class ClientCertificationBO {
  public id: number = 0;
  public clientId: number;
  public startOfCareId: string;
  public startDate: any;
  public endDate: any;
  public companyId: string;
  public icD10PrimaryId: string;
  public icD10SecondaryId: string;
  public npi: number;
  public physician: string;
  public clinic: string;
  public firstDigitFacilityLid: string = null;
  public secondDigitFacilityLid: string = null;
  public frequncyCodeLid: string = null;
  public priorityTypeOfAdmissionLid: string = null;
  public pointOfOriginForAdmissionOrVisitLid: string = null;
  public patientDischargeStatusLid: string = null;
  clinicCity: string;
  clinicState: string;
  clinicAddress: string;
  clinicPhone: string;
  zipCode: string;
  public is837I: boolean = false;
}
export interface PhysicianBO {
  id: number;
  nPI: string;
  physicianName: string;
  clinicName: string;
  clinicCity: string;
  clinicState: string;
  clinicAddress: string;
  clinicPhone: string;
  zipCode: string;
}


export class ContactsBO {
  public id: number = 0;
  public clientId: number;
  public emergencName: string;
  public emergencyRelationLid: string;
  public emergencySteet: string;
  public emergencyPhone: string;
  public emergencyState: string;
  public emergencyCity: string;
  public emergencyLiveWithLid: string;
  public emergencyZipCode: string;
  public emergencyAlternateName: string;
  public emergencyAlternateRelationLid: string;
  public emergencyAlternateSteet: string;
  public emergencyAlternatePhone: string;
  public emergencyAlternateState: string;
  public emergencyAlternateCity: string;
  public emergencyAlternateLiveWithLid: string;
  public emergencyAlternateZipCode: string;
  public guardianName: string;
  public guardianSteet: string;
  public guardianZipCode: string;
  public guardianPhone: string;
  public guardianState: string;
  public guardianCity: string;
  public guardianEmail: string;
  public guardianPhoneCarrierLid: number;
  public isSameAsGuardian: boolean;
  public responsiblePartyName: string;
  public responsiblePartySteet: string;
  public responsiblePartyZipCode: string;
  public responsiblePartyPhone: string;
  public responsiblePartyState: string;
  public responsiblePartyCity: string;
  public responsiblePartyEmail: string;
  public responsiblePartyCarrierLid: number;
}

export class ZipcodeDetailBO {
  id: number;
  agencyId: number | null;
  county: string;
  state: string;
  city: string;
  zipcode: string;
}

export class PhysicianBO {
  id: number;
  nPI: string;
  physicianName: string;
  clinicName: string;
  clinicCity: string;
  clinicState: string;
  clinicAddress: string;
  clinicPhone: string;
  zipCode: string;
}

export class sortingObj {
  itemperpage: number = 20;
  currentPgNo: number = 1;
  shortcolumn: string = "FirstName";
  shortType: string = 'asc';
}

export class clientFilter {
  clientStatus: number = 0;
}
export class carecoordinate {
  id: number;
  clientId: number;
  careCoordinatorId: number;
  careCoordinatorName: string;
  county: string;
  telephone: string;
  fax: string;
  alternateFax: string;
  email: string;
  statusName: string;
  statusLid: number;
  isDeleted: boolean | null;
}
export class housecoordinate {
  id: number;
  clientId: number;
  careCoordinatorId: number;
  careCoordinatorName: string;
  county: string;
  telephone: string;
  fax: string;
  alternateFax: string;
  email: string;
  statusName: string;
  statusLid: number;
  isDeleted: boolean | null;
}
export class ClientCoordinatorRelationshipBO{
  id: number;
  clientId: number;
  careCoordinatorId: number;
  statusLid: number;
  isDeleted: boolean | null;
  deletedBy: number | null;
  deletedDate: string | null;
  deletedIp: string;
}
export class ClientHousingCoordinatorRelationshipBO {
  id: number;
  clientId: number;
  houseCoordinatorId: number;
  statusLid: number;
  isDeleted: boolean | null;
  deletedBy: number | null;
  deletedDate: string | null;
  deletedIp: string;
}
export class relationship {
  Id: number
  RelationType: string
  type: string
}
export class getClient {
  searchColumn: string = "Name";
  searchText: string = "";
  orderColumn: string = "Name";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  agencyId: number;
  statusLid: number;
  employeeTypeLi: number;
  field: string = "Name";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  locationLid: number = 0;
  isMonitor:any;
  QpEmployeeId:number =0;

}
export class getMappedCMDataBO {
  id: number;
  clientId: number;
  caseManagerId: number;
  caseManagerName: string;
  county: string;
  telephone: string;
  fax: string;
  alternateFax: string;
  email: string;
  statusName: string;
  statusLid: number;
  isDeleted: boolean | null;
}
export class ClientCaseManagerRelationshipBO {
  id: number;
  clientId: number;
  statusLid: number;
  caseManagerId: number;
  isDeleted: boolean | null;
  deletedBy: number | null;
  deletedDate: string | null;
  deletedIp: string;
}
export class DocumentBO {
  id: number;
  clientId: number;
  documentNameLid: number | null;
  documentName: string;
  returnDocument: string;
  containerName: string;
  documentType: string;
  documentTitle: string;
  attachedDate: string | null;
  filepath: string;
  isRemoved: boolean | null;
  removedBy: string;
  removedDate: string | null;
  attachments: any;
}
export class ClientOnboardListBO {
  id: number = 0;
  clientId: number;
  onboardLid: number | null;
  onboardLidName: string;
  isCompleted: boolean;
  completedOn: string;
  expiredOn: string;
  fileName: string;
  fileString: string;
  containerName: string;
  filepath: string;
  uploadurl: string;
  onboardQuestion: string;
  attachments: any;
  value: number;
}


export class GetClientEmployeeRelationshipBO {
  id: number=0;
  clientId: number=0;
  employeeId: number=0;
  statusLid: number;
  employeeName: string;
  clientName: string;
  

  living: boolean;
    clientServiceId: any=[];
    activeCreatedDate: string="";
    inActiveDate:string | null;
}
export class ClientNoteBO {
  id: number;
  clientId: number;
  date: string;
  initial: string;
  notes: string;
  typeListId: number | null;
  multipletype: [];
}
export class type {
  id: number;
  typeNumber: string;
  typeName: string;
  typeFor: string;
  agencyId: number | null;
}
export class functionpermission {
  cilentcreate: boolean = true;
  cilentupdate: boolean = true;
  cilentview: boolean = true;
  cilentdelete:boolean=true;
  startofcarecreate: boolean = true;
  startofcaredelete: boolean = true;
  startofcareupdate: boolean = true;
  cretificationcreate: boolean = true;
  cretificationdelete: boolean = true;
  cretificationupdate: boolean = true;
  authorizationcreate: boolean = true;
  authorizationdelete: boolean = true;
  authorizationupdate: boolean = true;
  COB_Config_AddNew: boolean = true;
  COB_Config_CSVImport: boolean = true;
  CareplanDelete: boolean = true;
  RemoteSupportDelete: boolean = true;
  DocumentDelete: boolean = true;
  DynamicClientFormView: boolean = true;
}
export class ClientServiceActivityBO {
  id: number = 0;
  clientId: number;
  masterServiceActivityId: number;
  masterServiceId: number;
}
export class GetClientEvaluation {
  orderColumn: string = "Ages";
  orderType: string = "desc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "Ages";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
}

export class GetClientCasemanager {
  orderColumn: string = "CaseManagerName";
  orderType: string = "asc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "CaseManagerName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  statusLid:number;
}

export class GetClientCarecoordinator {
  orderColumn: string = "careCoordinatorName";
  orderType: string = "asc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "careCoordinatorName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  statusLid:number;
}
export class GetClientHousingcoordinator {
  orderColumn: string = "houseCoordinatorName";
  orderType: string = "asc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "houseCoordinatorName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  statusLid:number;
}


export class GetClientNotes {
  orderColumn: string = "Date";
  orderType: string = "DESC";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "Initial";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
}

export class SOCListBO {
  orderColumn: string = "CompanyName";
  orderType: string = "asc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "CompanyName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
}

export class AuthListBO {
  orderColumn: string = "id";
  orderType: string = "desc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "companyName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  state: string = "active";
}

export class CertificationListBO {
  orderColumn: string = "companyName";
  orderType: string = "asc";
  pageitem: number = 10;
  clientId: number;
  ages: number;
  client: string;
  currentpageno: number = 1;
  field: string = "companyName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
}
export class IsViewEditSchedule {
  isView: boolean;
  isEdit: boolean;
  isAdd: boolean;
  // isEditCompany:boolean;
  editScheduleId: number;
  AgencyId: number;
  ClientId: number;
  Data: null;
}
export class ConsumerAssessmentBO {
  id: number = 0;
  consumer: string = '';
  consumerDate: string = '';
  clientId: number;
  sameCarePaln: boolean;
  verbage: string;
}

export class VerbageBO {
  sameVerbage: boolean;
  verbage: string;
}

export class EditDetailsConsumerAssessment {
  isView: boolean = false;
  isEdit: boolean = false;
  isEditClient: boolean = false;
  clientId: number = 0;
  iscounsumer: boolean = false;
  consumerId: number = 0;
  clientData: ClientBO = new ClientBO();
  consumerData: ConsumerAssessmentBO = new ConsumerAssessmentBO();
}

//===================Edit Medication model=======================//
export class EditDetailsMedication {
  isView: boolean = false;
  isEdit: boolean = false;
  isEditMedication: boolean = false;
  MedicationId: number = 0;
  MedicationData: ClientMedicationBO = new ClientMedicationBO();
  ClientId: number = 0;
  type: string = '';
}


export class ICDCODEConsumerBO {
  icdCode: string;
  icdDesc: string;
} export class InsuranceBO {
  insuranceNumber: string;
}

export class ClientMedicationBO {
  id: number = 0;
  clientId: number;
  medication: string = '';
  medicationID: number;
  apperance: string = '';
  rxNumber: string;
  howMany: number | null;
  howTakenLid: string;
  medicationFrequncyLid: string;
  refills: number | null;
  startDate: string | null;
  endDate: string | null;
  reason: string = '';
  prescribedBy: string;
  notes: string;
  pharmacyId: string;
  pharmacyName: string;
  pharmacyAddress: string;
  pharmacyCity: string;
  pharmacyState: string;
  pharmacyZipcode: string;
  pharmacyPhone: string;
  rnum: string;
  npi: string;
  statusLid: number;
  status_Name: string;
}
export class MedicationName {
  name: string = "";
  id: number = 0;
}
export class SaveReturnBOMed {
  id: number;
  errorList: string[];
}

export class GetClientMedicationBO {
  orderColumn: string = "medication";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  userId: number;
  clientId: number | null;
  field: string = "medication";
  value: string = "";
  matchCase: boolean = false;
  type: string = "string";
  operator: string = "contains";
}

export class GetPharmacyBO {
  orderColumn: string = "pharmacyName";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  userId: number;
  clientId: number | null;
  agencyId: number | null;
  field: string = "pharmacyName";
  value: string = "";
  matchCase: boolean = false;
  type: string = "string";
  operator: string = "contains";
}
export class PharmacyBO {
  id: number = 0;
  pharmacyName: string;
  pharmacyAddress: string;
  pharmacyCity: string;
  pharmacyState: string;
  pharmacyZipcode: string;
  pharmacyPhone: string;
  agencyId: number = 0;
}
export class SaveReturnBOPharmacy {
  id: number;
  errorList: string[];
}

export class dateExpireList {
  todaylist: ClientMedicationBO[];
  beforelist: ClientMedicationBO[];
}

export class returnStartOfCareWithCompany {
  startOfCareId: string;
  companyId: string;
  socDateWithCompanyName: string;
}

export class LanguageMappingBO {
  id: number;
  clientId: number | null = null;
  employeeId: number | null = null;
  languageLid: number;
  languageLidName: string;
}

export class LanguageMappingSaveBO {
  clientId: number | null;
  employeeId: number | null;
  languageLids: number[] = [];
}
export class ServiceDetailBO {
  serviceDescription: string;
  netRate: number | null;
  ucRate: number | null;
  authLimitType: string;
}

export class EDI271ClientBO {

  ClientIdQualifier: string;
  ClientID: string;
  ucRate: number | null;
}
export class EDI271ClientReportBO {
  clientIdQualifier: string;
  clientID: string;
  clientName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  dOB: string;
  gender: string;
  lastEligibilityCheck: string | null;
  clientPkId: number | null;
  medicaid: string;
  isMAEligible: boolean;
  pMAP: string;
  program: string;
  waiver: string;
  spendDown: boolean;
  mSHO: boolean;
  messageList: string[];
  messages: string;
  loopDetails: EDI271LoopDetailBO[];
  aAADetails: EDI271AAADetailBO[];
}

export class GetEDI271ClientBO {
  orderColumn: string = "ClientName";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  userId: number;
  clientId: number | null;
  agencyId: number | null;
  field: string = "ClientName";
  value: string = "";
  matchCase: boolean = false;
  type: string = "string";
  operator: string = "contains";
  locationId: number | null;
}

export class OnClientboardList {
  id: number;
  clientId: number;
  onboardLid: number;
  isCompleted: boolean | null;
  completedOn: string | null;
  expiredOn: string | null;
  containerName: string;
  filePath: string;
  fileName: string;
  uploadurl: string;
  onboardQuestion: string;
}
export class functionpermission1 {
  employeecreate: boolean = true;
  employeeupdate: boolean = true;
  payorrequiredcreate: boolean = true;
  payorrequireddelete: boolean = true;
  payorrequiredupdate: boolean = true;
  payratescreate: boolean = true;
  payratesdelete: boolean = true;
  payratesupdate: boolean = true;
  EOB_OnboardCheckList: boolean = true;
  EOB_AddNew: boolean = true;
  EOB_CSVImport: boolean = true;
  onboardtype1: boolean = true;
  onboardtype2: boolean = true;
  viewofficestaff: boolean = false;
}

export class returnclientonboard {
  containerName: string;
  filePath: string;
  fileName: string;
  onboardLid: number;
  uploadurl: string;
}
export class dateExpireClientListOnboard {
  expiringList: OnClientboardList[];
  expiredList: OnClientboardList[];
}

export class GetClientTypeBO {
  typefor: string;
  agencyId: number | null;
  orderColumn: string = "Id";
  orderType: string = "DESC";
  pageitem: number = 500;
  currentpageno: number = 1;
  field: string = "TypeNumber";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  clientId: number | null;
  locationId: number | null;
}
export class SaveOnboardListBO {
  id: number = 0;
  clientId: number;
  employeeId: number;
  onboardLid: number;
  fileString: string;
  isCompleted: boolean | null;
  completedOn: string | null;
  expiredOn: string | null;
  containerName: string;
  filePath: string;
  fileName: string;
  uploadurl: string;
  onboardQuestion: string;
  onboardLidName: string;
}
export class ResponsibleInfoLst {
  name: string;
  email: string;
  alternatePhoneNumber: string;
  city: string;
  county: string;
  fax: string;
  phoneNumber: string;
  relationship: string;
  state: string;
  street: string;
  zipCode: string;
}

export class EmergencyContactInfoLst {
  alternatePhoneNumber: string;
  city: string;
  county: string;
  email: string;
  fax: string;
  name: string;
  phoneNumber: string;
  relationship: string;
  state: string;
  street: string;
  zipCode: string;
}
export class CaregiverInfoLst {
  age: string;
  apt: string;
  city: string;
  county: string;
  email: string;
  name: string;
  phone1: string;
  phone2: string;
  steet: string;
  street: string;
  zipcode: string;
  relationContactType:string
}
export class OfficeCaseManagerInfoLst {
  alternateFax: string;
  county: string;
  email: string;
  fax: string;
  name: string;
  phone: string;
}

export class CareCoordinatorInfoLst {
  alternate_Fax: string;
  careCoordinatorName: string;
  county: string;
  email: string;
  fax: string;
  telephone: string;
}
export class QPHistoryLst {
  currentStatus: string;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string | null;
  mappedBy: string;
  mappedOn: string | null
  name: string;
}

export class QPBO {
  Id: number

}
export class qpList {
  age: number;
  apt: string;
  city: string;
  county: string;
  email: string;
  name: string;
  phone1: string;
  phone2: string;
  steet: string;
  street: string;
  zipcode: string;
  activeDate:string; 
}

export class AuthorizationFilterBo {
  TypeFilter: string;
}

export class saveBasicCarePlanBo {
  id: number = 0;
  clientId: number;
  agencyId: number | null;
  agencyName: string;
  agencyLocation: number | null;
  agencyCity: string;
  agencyAddress: string;
  agencyState: string;
  agencyZipcode: string;
  agencyEmail: string;
  agencyPhone: string;
  agencyFax: string;
  clientFirstName: string;
  clientLastName: string;
  clientMiddleName: string;
  clientDOB: string;
  clientCity: string;
  clientAddress: string;
  clientState: string;
  clientZipcode: string;
  clientEmail: string;
  clientPhone: string;
  clientCounty: string;
  clientFullName: string;
  IshealthInsurance: boolean | null;
  medicaid: string;
  medicare: string;
  insuranceNo: string;
  nPI: string;
  frequencyOfVisit: number | null;
  clientAuthId: number | null;
  qpEmployeeID: number | null;
  payorId: number | null;
  physician: string;
  payor: string;
  physicianAddress: string;
  physicianPhone: string;
  icd10primaryId: string;
  icd10Desc: string;
  serviceId: number | null;
  serviceDescription: string;
  transportation: string;
  clientSummary: string;
  goals: string;
  other: string;
  backupStaffingPlan: string;
  emergencyProceduresPlan: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  qPPhone: string;
  qPName: string;
  caseManagerName: string;
  caseManagerPhone: string;
  responsiblePartySign: string;
  pcaSign: string;
  nurseSign: string;
  clientSign: string;
  responsiblePartySignUrl: string;
  pcaSignUrl: string;
  nurseSignUrl: string;
  clientSignUrl: string;
  responsiblePartySignDate: string | null;
  pcaSignDate: string | null;
  nurseSignDate: string | null;
  clientSignDate: string | null;
  date: string;
  serviceactivity: BasicCarePlanServiceActivitiesBO[];
  height: number | null;
  weight: number | null;
}
export class BasicCarePlanServiceActivitiesBO {
  id: number;
  basiccareplanId: number;
  masterServiceActivityId: number;
  masterServiceActivityName: string;
  serviceActivitiesComment: string;
}



export class homemakingCarePlanServiceActivitiesBO {
  id: number;
  homemakingCarePlanId: number;
  masterServiceActivityId: number;
  masterServiceActivityName: string;
  serviceActivitiesComment: string;
  isChecked: boolean;
  everyVisit: boolean;
  evertWeek: boolean;
}
export class ClientQpInfoBO {
  orderColumn: string = "clientName";
  orderType: string = "DESC";
  pageitem: number = 10;
  currentpageno: number = 1;
  field: string = "clientName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  employeeId: number;
  LocationID: number | null;
  clientId: number;
  AgencyID: number | null;
  statusLid: number
}
export class ClientServiceIdBO {
  id: number;

}


export class GetUcareListBO {
  orderColumn: string = "createdDate";
  orderType: string = "asc";
  field: string = "createdDate";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "datetime";
  searchColumn: string;
  searchText: string;
  currentpageno: number = 1;
  pageitem: number = 10;
  agencyID: number | null;
  locationId: number | null;
  clientId: number;


}
export class GetPhysicanBO {
  orderColumn: string = "PhysicianName";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  userId: number;
  clientId: number | null;
  field: string = "PhysicianName";
  value: string = "";
  matchCase: boolean = false;
  type: string = "string";
  operator: string = "contains";
}


export class ClientAuthorizationReportBo {
  orderColumn: string = "clientName";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  agencyId: number;
  field: string = "clientName";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "string";
  locationID: number = 0;
  isHousing: boolean=false;
  serviceTypeLid: number;
  dateType:boolean=false;
}

export class saveIclsListBo {
  id: number;
  clientId: number;
  agencyId: number | null;
  agencyName: string;
  clientName: string;
  clientDOB: string;
  clientCity: string;
  clientAddress: string;
  clientState: string;
  clientZipcode: string;
  clientEmail: string;
  clientPhone: string;
  clientPhone2: string;
  clientCounty: string;
  clientHealthInsurance: boolean;
  clientHistory: string;
  goals: string;
  carePlanDate: string;
  allergies: string;
  serviceId: number | null;
  emergencyContactName: string;
  emergencyContactPhone: string;
  legalRepresentativeName: string;
  legalRepresentativePhone: string;
  workOrientedClient: string;
  iCLSCoordinatorSign: string;
  iCLSWorkersSign: string;
  clientSign: string;
  iCLSCoordinatorSignUrl: string;
  iCLSWorkerSignUrl: string;
  clientSignUrl: string;
  iCLSCoordinatorSignDate: string | null;
  clientSignDate: string | null;
  iCLSWorkerSignDate: string | null;
  frequencyOfVisit: number | null;
  date: string;
  resuscitation: boolean | null;
  livingWill: boolean | null;
  dNRDNI: boolean | null;
  noCode: boolean | null;
  startOfDate: string;
  endOfDate: string;
  serviceActivity: ICLSCarePlanServiceActivitiesBO[];
  height: number | null;
  weight: number | null;
}

export class ICLSCarePlanServiceActivitiesBO {
  id: number;
  iCLSCarePlanId: number;
  masterServiceActivityId: number;
  masterServiceActivityName: string;
  serviceActivitiesComment: string;
  sunday: boolean | null;
  monday: boolean;
  tuesday: boolean | null;
  wednesday: boolean | null;
  thursday: boolean | null;
  saturday: boolean | null;
  friday: boolean | null;
  asNeeded: boolean | null;
}



export class saveUcareListBo {
  id: number = 0;
  clientId: number;
  clientName: string;
  clientGender: string;
  clientPhone: string;
  clientAddress: string;
  clientDOB: string | null;
  icD10Code: string;
  cityStateZip: string;
  medicaid: string;
  insuranceId: string;
  physicianName: string;
  physicianAddress: string;
  physicianCityStateZip: string;
  physicianPhone: string;
  appoinmentContactName: string;
  appoinmentContactRelationShip: string;
  appoinmentContactPhone: string;
  appoinmentContactBestTimeToContact: string;
  appoinmentContactInterpreterNeeded: boolean | null;
  appoinmentContactLanguage: string;
  authStartDate: string | null;
  authEndDate: string | null;
  authNumber: string;
  remainingHours: string;
  agencyName: string;
  agencyPhone: string;
  agencyFax: string;
  physicianNPI: string;
  physicianFax: string;
  careGiver1Name: string;
  careGiver1UMPI: string;
  careGiver2Name: string;
  careGiver2UMPI: string;
  initialAssesment: boolean;
  annualReAssesment: boolean;
  earlyAssement: boolean;
  doesMemberRequireRP: boolean | null;
  responsibleParty: string;
  rPPhone: string;
  rPRelationship: string;
}
export class SaveHealthPartnersReassessmentBo {
  id: number = 0;
  clientId: number;
  clientName: string;
  clientGender: string;
  clientPhone: string;
  clientDOB: string;
  assessmentTypeInitial: boolean | null;
  assessmentTypeAnnual: boolean | null;
  assessmentTypeEarly: boolean | null;
  isSupplemental: boolean | null;
  healthPatnerId: string;
  livesAlone: boolean | null;
  liveswithPCA: boolean | null;
  liveswithOthers: boolean | null;
  familyFosterHome: boolean | null;
  clientAddress: string;
  clientState: string;
  clientCity: string;
  clientZip: string;
  homePhone: string;
  primaryContact: string;
  phone: string;
  primaryDoctor: string;
  primaryClinic: string;
  clinicPhone: string;
  contactFax: string;
  contactAddress: string;
  contactState: string;
  contactCity: string;
  contactZip: string;
  diagnosisPcaService1: string;
  icd101: string;
  diagnosisPcaService2: string;
  icd102: string;
  diagnosisPcaService3: string;
  icd103: string;
  language: string;
  interpreterNeeded: boolean | null;
  signLanguageInterpreterNeeded: boolean | null;
  hospitalization_SkilledNursingFacility: boolean | null;
  directOwnCare: boolean | null;
  responsiblePartyName: string;
  responsiblePartyRelationship: string;
  responsiblePartyPhone: string;
  currentlyreceivingPcaServices: boolean | null;
  pcaUnits: string;
  authPeriod: string;
  snv: boolean | null;
  pt: boolean | null;
  ot: boolean | null;
  none: boolean | null;
  hha: boolean | null;
  ev: boolean | null;
  homemaking: boolean | null;
  howoffenWeekly: boolean | null;
  howoffenBiWeekly: boolean | null;
  howoffenMonthly: boolean | null;
  howoffen_other: string;
  medicareCertified: boolean | null;
  pcaVendarAddress: string;
  pcaVendarCity: string;
  pcaVendarState: string;
  pcaVendarZip: string;
  taxId: string;
  vendarPhone: string;
  vendarFax: string;
  vendarQualifiedProf: string;
  vendarCredentials: string;
  pcaName1: string;
  pcaName2: string;
  relationshipMember1: string;
  relationshipMember2: string;
  casemanagerName: string;
  casemanagerPhone: string;
  todayDate: string | null;
  completedBy: string;
  nurseReviewer: string;
}
export class GetImportAuthorizationBo {
  clientId: number;
  orderColumn: string = "startDate";
  orderType: string = "asc";
  pageitem: number = 10;
  currentpageno: number = 1;
  field: string = "startDate";
  value: string = "";
  matchCase: boolean = false;
  operator: string = "contains";
  type: string = "date";
  client: string;
  employeeID: number | null;

}


export class InterpreterContactInfoLst {
  alternatePhoneNumber: string;
  city: string;
  county: string;
  email: string;
  fax: string;
  name: string;
  phoneNumber: string;
  relationship: string;
  state: string;
  street: string;
  zipCode: string;
}

export class HousingCoordinatorInfoLst {
  alternate_Fax: string;
  houseCoordinatorName: string;
  county: string;
  email: string;
  fax: string;
  telephone: string;
}

export class PageIdentifyBo {
  isHousing:boolean=false;
  PageTypeLid:number;
  PageIdentity:string=""
}


export class AssignCaseManager {
  id: number=0;
  clientId: number;
  statusLid: number;
  caseManagerId:number=0;
  activeDate:string;
  inActiveDate:string;

}
export class AssignCareCoordinator {
  id: number=0;
  clientId: number;
  careCoordinatorId:number=0;
  statusLid: number;
  activeDate:string;
  inActiveDate:string;

}
export class AssignHouseCoordinator {
  id: string="00000000-0000-0000-0000-000000000000";
  clientId: number=0;
  houseCoordinatorId:string="00000000-0000-0000-0000-000000000000";
  statusLid: number;
  activeDate:string;
  inActiveDate:string;

}

export class AuthEmailLetterBO {
  clientId: number;
  employeeId: number[];
  locationId: number | null;
  dateType:boolean | null;
}


export class GetClienRelationBo {
  orderColumn: string = "ClientName";
  orderType: string = "asc";
  pageitem: number = 10;
  clientId: number=0;
  ages: number | null;
  client: string;
  currentpageno: number = 1;
  field: string = "ClientName";
  value: string = "";
  matchCase: boolean = false;
  type: string = "string";
  operator: string = "contains";
  employeeID: number | null;
  locationID: number=0;
  agencyID: number;
  statusLid: number;
}

export class EmitMonitorList{
  isClient:boolean=false;
  clientId:number=null;
  employeeId:number=null;
  isView:boolean=false;
  isAdddedClientMonitor:boolean=false;
  isAdddedEmployeeMonitor:boolean=false;
}


export class EmitClientProgressReport{
  clientId: number=null;
  isClient:boolean=false;
  ElectronicTemplateId:number=null;
  DynamicTemplateId:string="00000000-0000-0000-0000-000000000000";
  homemakingviews:boolean=false;
}



export class getClientprogressReportBo {
  orderColumn: string = "templateName";
  currentpageno: number = 1;
  pageitem: number = 10;
  orderType: string = "asc";
  type: string = "string";
  field: string = "templateName";
  operator: string = "contains";
  value: string = "";
  entityTypeCode: string;
  matchCase: boolean = false;
  agencyId: number | null;
  clientId: number;
  fromDate:string;
  toDate:string;
  formtypeFilter:string="";
}

export class ClientProgressFieldBo {
  StartDate: any;
  EndDate: any;
  ValidationDate: any;
  PdfString: any = "";
  FilePath: any = ''
  FolderName: any = ''
  PrintPdf: any = ''
  templateName:any="";
  EvaluationNotesList: any="";
}
export class FormsBooleanBo {
  isComprehensiveNew :boolean= false
  isSupervision :boolean= false;
  isBasicCarePlan :boolean= false;
  ishomeCarePlan :boolean= false;
  isIclsCarePlan :boolean= false;
  isUCareBoolean :boolean=false;
  isHCareReassessment :boolean=false;
  isForms :boolean= false;
  isDynamic :boolean=false;
  isNotes :boolean= false;
}