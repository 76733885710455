<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <i class="fa fa-moon-o" style="color: blue;font-size: 20px;" aria-hidden="true"
            *ngIf="global.checkidle==true" title="Billing is in Idle"></i>
        <i class="fa fa-check-circle-o" style="color: #07da07;font-size: 20px;"
            *ngIf="global.checkclaimProgress==false" title="Billing process completed"></i>
        <i class="fa fa-spinner fa-spin" style="color: #077bda;font-size: 20px;" *ngIf="global.checkclaimProgress==true"
            aria-hidden="true" title="Billing is in-progress"></i>

    </a>
    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div style="margin: auto;font-size: 16px;text-align: center;margin-top: 18%;" *ngIf="global.checkidle==true">
            <i class="fa fa-moon-o" style="color: blue;font-size: 20px;margin-right: 10%;" aria-hidden="true"></i>
            <br>
            Billing is in
            <span style="color:blue;font-weight: bold;">Idle</span> state.
        </div>

        <div style="margin: auto;font-size: 16px;text-align: center;margin-top: 18%;"
            *ngIf="global.checkclaimProgress==true">
            <i class="fa fa-spinner fa-spin" style="color: #077bda;font-size: 80px;" aria-hidden="true"></i>
            <br>
            Billing
            <span style="color: #077bda;font-weight: bold;">is in-progress.</span>
            So you can't send timesheets to billing.
        </div>

        <div style="margin: auto;font-size: 16px;text-align: center;margin-top: 18%;"
            *ngIf="global.checkclaimProgress==false">

            <i class="fa fa-check-circle-o" style="color: #07da07;font-size: 80px;" aria-hidden="true"></i>

            <br>
            Billing
            <span style="color: #07da07;font-weight: bold;">process completed.</span>
            Now you can send timesheets to billing.
        </div>
    </div>
</div>