import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { interval } from "rxjs";
import { GlobalComponent } from "./global/global.component";
@Injectable({
    providedIn: 'root'
})
export class PWAService {
    constructor(public updates: SwUpdate, public global: GlobalComponent) {
        if (!updates.isEnabled) {
            return;
        }
        if (updates.isEnabled) {
            interval(3600000).subscribe(() => updates.checkForUpdate()
                .then(() => console.log()));
        }
    }
    public checkForUpdates(): void {
        this.global.checkForAppUpdateAvailable();
    }
}
