export class Employee {
    id: number = 0;
    name: string;
    agencyId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    type: boolean;
    dob: string;
    genderLid: number;
    statusLid: number;
    statusLidName: string;
    totalhours: number | null;
    employeeTypeLid: number | null;
    employeeTypeLids: any = [];
    employeeTypeLidName: string;
    age: number | null;
    aka: string;
    umpi: string;
    maritalStatusLid: number | null;
    rnnpi: string;
    street: string;
    apt: string;
    email: string;
    phone1: string;
    phone2: string;
    steet: string;
    city: string;
    county: string;
    zipcode: string;
    ssn: string;
    locationStatusLid: number | null;
    requiredEVV: boolean | null = false;
    primaryJobId: number | null;
    veteranDisabledLid: number | null;
    disabilityLid: number | null;
    raceEthnicityLid: number | null;
    hiredate: string | null;
    inactivedate: string | null;
    terminationdate: string | null;
    workingHours: number | null;
    //loginUserId: string;
    exceededHours: number | null;
    PayrollList: PayRollInformation[] = [];
    PayrateList: PayRateUnit[] = [];
    PayorreqList: PayorRequiredID[] = [];
    username: string | null;
    password: string | null;
    loginUserId: string | null;
    pEmployeeId: number | null;
    ivrEmployeeId: string;
    EmployeeId: number | null;
    officeStaff: boolean = false;
    isTestingEntity: boolean=false;
    pcaCertificateNumber: number | null;
    immunizationLids: any[];
    isEligibleForManualETimesheet: boolean = false;
    version: string;
    alter_Street: string;
    alter_City: string;
    alter_State: string;
    alter_Zipcode: string;
    alter_Phone: string;
    alter_Email: string;
    secondaryApartment:string;
    isHomelessEmployee: boolean=false;
    isMonitor:boolean=false;
    isSingleDateEmanualEntry:boolean=false;
    userRole: string;
}

export class KeyStatusEmpBO {
    id: number;
    hiredate: string | null;
    inactivedate: string | null;
    terminationdate: string | null;
    
}
export class PayRollInformation {
    id: number = 0;
    employeeId: number;
    withHoldingLid: number | null;
    exemptions: string;
    paycheckTypeLid: number | null;
    otherPayrollInfo: string;
    bankRounting: string;
    accNumber: string;
    allCompanyPayrollID: string;
}
export class PayRateUnit {
    id: number;
    employeeId: number;
    masterServiceId: number;
    startDate: string;
    endDate: string;
    payRate: string;
    managePayrateId: number;
    payRateforRelative: string;
    masterServiceName: string;
    payarateunit: string;
    exceptionalRatesList: ExceptionalRateBO[];
}
export class PayorRequiredID {
    id: number;
    employeeId: number;
    groupPayorId: number | null;
    billingPayorRequiredID: string;
    startDate: string | null;
    endDate: string | null;
    faxDate: string | null;
    received: boolean | null;
    isReceived: string;
    payorName: string;
}
export class zip {
    agencyId: number
    city: string
    county: string
    id: number
    state: string
    zipcode: string
}
export class functionpermission {
    employeecreate: boolean = true;
    employeeupdate: boolean = true;
    employeedelete:boolean=true;
    payorrequiredcreate: boolean = true;
    payorrequireddelete: boolean = true;
    payorrequiredupdate: boolean = true;
    payratescreate: boolean = true;
    payratesdelete: boolean = true;
    payratesupdate: boolean = true;
    EOB_OnboardCheckList: boolean = true;
    EOB_AddNew: boolean = true;
    EOB_CSVImport: boolean = true;
    onboardtype1: boolean = true;
    onboardtype2: boolean = true;
    viewofficestaff: boolean = false;
    EmployeeCareplanDelete:boolean=true;
    DocumentDelete:boolean=true;
    DynamicEmployeeFormView:boolean=true;
    Trainer:boolean=false;
}
export class sortingObj {
    itemperpage: number = 20;
    currentPgNo: number = 1;
    shortcolumn: string = "FirstName";
    shortType: string = 'asc';
}
export class employeeFilter {
    employeeType: number;
    employeeStatus: number;
    isMonitor:any;
}
export class IsViewEdit {
    isView: boolean;
    isEdit: boolean;
    isEditEmployee: boolean;
    editEmployee: number;
    Employeedata: Employee;
    EmployeefilterDetails: getEmployee = new getEmployee();
    isEmployeefilterEnable: boolean = false;
    employeeId: number;
    ICSId: number = 0;
    isPreviousRecordId:number=0;
    isCheckEmployeeView:boolean|null;
    isEditICSPlan: boolean = false;
}
export class IsViewEditpayorequired {
    isView: boolean;
    isEdit: boolean;
    isEditPayorRequired: boolean;
    employeeId: number;
    payorRequiredId: number;
    payorRequired: PayorRequiredID[]
    payorActiotype: string;
}
export class IsViewEditpayrate {
    isView: boolean;
    isEdit: boolean;
    istemplate:boolean;
    isEditPayorrate: boolean;
    employeeId: number;
    payrateId: number;
    payratetype: string;
    payrate: PayRateUnit[]
}
export class OnboardList {
    id: number;
    employeeId: number;
    onboardLid: number;
    isCompleted: boolean | null;
    completedOn: string | null;
    expiredOn: string | null;
    containerName: string;
    filePath: string;
    fileName: string;
    uploadurl: string;
    onboardQuestion: string;
}
export class returnonboard {
    containerName: string;
    filePath: string;
    fileName: string;
    onboardLid: number;
    uploadurl: string;
}


export class ManagePayrate {
    id: number;
    employeeId: number;
    payarateunit: string;
    payarateLid: number;
    payratelidName: string;
}

export class getEmployee {
    searchColumn: string = "Name";
    searchText: string = "";
    orderColumn: string = "Name";
    orderType: string = "asc";
    pageitem: number = 10;
    currentpageno: number = 1;
    agencyId: number;
    statusLid: number;
    employeeTypeLi: number;
    field: string = "Name";
    value: string = "";
    matchCase: boolean = false;
    operator: string = "startswith";
    type: string = "string";
    locationLid: number = 0;
    message: string;
    isMonitor:boolean;
    sign:string;
}
export class RetrunEmployeeBO {
    employeelst: Employee[];
    employeeTotal: number;
}
export class relationship {
    Id: number
    RelationType: string
    type: string

}
export class GetpayrateBO {
    orderColumn: string = "PayRate";
    orderType: string = "asc";
    pageitem: number = 10;
    clientId: number;
    ages: number | null;
    client: string;
    currentpageno: number = 1;
    field: string = "PayRate";
    value: string = "";
    matchCase: boolean = false;
    type: string = 'string';
    operator: string = "contains";
    employeeID: number | null;
    relationshipFilter: number | null;
    agency: number | null;
}

export class GetpayeridBO {
    orderColumn: string = "PayorName";
    orderType: string = "asc";
    pageitem: number = 10;
    clientId: number;
    ages: number | null;
    client: string;
    currentpageno: number = 1;
    field: string = "PayorName";
    value: string = "";
    matchCase: boolean = false;
    type: string = 'string';
    operator: string = "contains";
    employeeID: number | null;
    relationshipFilter: number | null;
    agency: number | null;
}
export class dateExpireListOnboard {
    expiringList: OnboardListBO[];
    expiredList: OnboardListBO[];
}
export class OnboardListBO {
    id: number = 0;
    employeeId: number;
    onboardLid: number;
    isCompleted: boolean | null;
    completedOn: string | null;
    expiredOn: string | null;
    containerName: string;
    filePath: string;
    fileName: string;
    uploadurl: string;
    onboardQuestion: string;
}
export class EmployeeNoteBO {
    id: number;
    employeeId: number;
    date: string;
    initial: string;
    notes: string;
    typeListId: number | null;
    typeListName: string;
}
export class WhereCondition {
    field: string = "CareCoordinatorName";
    value: string = "";
    operator: string = "startswith";
    orderNo: number = 1;
    predicate: string = "and";
    type: string = "string";
    matchCase: boolean = false;
}
export class GetListBO1 {
    type: string;
    field: string = 'ClientName';
    operator: string;
    value: string;
    matchCase: boolean;

    orderColumn: string = 'TimesheetDate';
    orderType: string = 'ASC';

    searchColumn: string;
    searchText: string = "";

    currentpageno: number = 1;
    pageitem: number = 10;

    agencyID: number | null;
    locationId: number | null;

    employeeId: number;
    clientId: number;
    supervisorId: number;
    statusLid: number;

    spendownfilter: boolean | null;
    ptype: number | null;
    role: string;
    user: number | null;
    menuID: number | null;
    parentMenuID: number | null;
    listId: number | null;
    lovCode: number | null;
    userId: number | null;
    isEvv: boolean;
    formtype: string;
    formuserId: number;
}

export class GetListBO {
    orderColumn: string = "Date";
    orderType: string = "DESC";
    pageitem: number = 10;
    currentpageno: number = 1;
    field: string = "Initial";
    value: string = "";
    matchCase: boolean = false;
    operator: string = "contains";
    type: string = "string";
    employeeId: number;
    locationId: number | null;
    supervisorId: number;
}
export class EmpSupListBO {
    orderColumn: string = "SupervisorName";
    orderType: string = "DESC";
    pageitem: number = 10;
    currentpageno: number = 1;
    field: string = "SupervisorName";
    value: string = "";
    matchCase: boolean = false;
    operator: string = "contains";
    type: string = "string";
    employeeId: number;
    LocationID: number | null;
    supervisorId: number;
    AgencyID: number | null;
    statusLid: number
}

export class type {
    id: number;
    typeNumber: string;
    typeName: string;
    typeFor: string;
    agencyId: number | null;
}
export class ExceptionalRateBO {
    id: number=0;
    clientId: number=0;
    exceptionalRate: string='';
    startDate: string ="";
    endDate: string ="";
}
export class SupervisorMappingBO {
    Supervisor: number = 0;
    Employee: number = 0;
    Status: number = 0;
}
export class EmployeeSupervisorRelationshipBO {
    id: number;
    supervisorId: number;
    supervisorName: string;
    employeeId: number;
    employeeName: string;
    statusLid: number;
    statusLovName: string;
}

export class SuperVisorBo {
    name: string;
    phone: string;
}
export class EmergencyContactBo {
    name: string;
    phone: string;
}
export class ResponsiblePartyBo {
    name: string;
    relationship: string;
    phone: string;
}




export class GetListBO12 {
    typefor: string;
    agencyId: number | null;
    orderColumn: string = "Id";
    orderType: string = "DESC";
    pageitem: number = 500;
    currentpageno: number = 1;
    field: string = "TypeNumber";
    value: string = "";
    matchCase: boolean = false;
    operator: string = "contains";
    type: string = "string";
    employeeId: number;
    locationId: number | null;
}

export class SaveOnboardListBO {
    id: number = 0;
    employeeId: number;
    onboardLid: number;
    fileString: string;
    isCompleted: boolean | null;
    completedOn: string | null;
    expiredOn: string | null;
    containerName: string;
    filePath: string;
    fileName: string;
    uploadurl: string;
    onboardQuestion: string;
    onboardLidName: string;
}
export class EducationDetailBO {
    id: number;
    employeeId: number;
    schoolName: string;
    schoolLocation: string;
    startDate: string | null;
    endDate: string | null;
    graduatedDate: string | null;
    degreeOrCertification: string;
    remarks: string;
    showEducationDetails: boolean = false;
}
export class EmploymentHistoryBO {
    id: number;
    employeeId: number;
    employer: string;
    employerAdress: string;
    startDate: string | null;
    endDate: string | null;
    positionTitle: string;
    endingSalary: number | null;
    reasonForLeaving: string;
    showEmpHistoryDetails: boolean = false;
}

export class isEventEmitEducation {
    isEducationValidate: boolean;
    isEmployeeId: number;
    isEducationId: number;
    isEducationTypes: string;
    isEducationData: EducationDetailBO[]
}

export class isEventEmitEmploymentHistory {
    isEmploymentHistoryValidate: boolean;
    isEmployeeId: number;
    isEmploymentHistoryId: number;
    isEmploymentHistoryTypes: string;
    isEmploymentHistoryData: EmploymentHistoryBO[]
}

export class isEventEmitWorkHistory {
    Id: number;
    HireDateWorkHistory: string;
    TerminationDateWorkHistory: string;
    InActiveDateHistory: string;

}
export class OnboardExpirationReportBo {
    startDate: any = null;;
    endDate: any = null;
}
export class saveICSSupervisionListBo {
    id: number=0;
    employeeName: string;
    employeeId: number;
    title: string;
    notes: string;
    supervisorSign: string;
    clientIdslist: string;
    dateIn: string | null;
    dateOut: string | null;
    clientIds: number[];
    icssupervisionDate:string;
}

export class GetICSListBO {
    orderColumn: string ="createdDate";
    orderType: string = "asc";
    field: string = "createdDate";
    value: string = "";
    matchCase: boolean = false;
    operator: string = "contains";
    type: string = "datetime";
    searchColumn: string;
    searchText: string;
    currentpageno: number=1;
    pageitem: number=10;
    agencyID: number | null;
    locationId: number | null;
    employeeId: number;
   
    
  }



export class savePayrateTemplateBO {
    id: number=0;
    templateId: string;
    employeeId: number=0;
    payrateTempExceptionalBO: PayrateTempExceptionalBO[]=[];
}


export class PayrateTempExceptionalBO {
    id: string;
    masterServiceId: number;
    startDate: string;
    endDate: string | null;
    payRate: string;
    managePayrateId: any;
    payRateforRelative: string;
    masterServiceName: string;
    payarateunit: string;
    exceptionalRatesList: ExceptionalRateBO[];
}


export class GetpayrateTemplateBO {
    orderColumn: string = "TemplateName";
    orderType: string = "asc";
    pageitem: number = 10;
    clientId: number;
    ages: number | null;
    client: string;
    currentpageno: number = 1;
    field: string = "TemplateName";
    value: string = "";
    matchCase: boolean = false;
    type: string = 'string';
    operator: string = "contains";
    employeeID: number | null;
    relationshipFilter: number | null;
    agency: number | null;
}



export class BulkUpdatePayRate {
    id: number;
    masterServiceId: number;
    startDate: string;
    endDate: string;
    payRate: string;
    managePayrateId: number;
    payRateforRelative: string;
    masterServiceName: string;
    payarateunit: string;
    newPayrateEndDate:string;
}

