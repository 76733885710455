import { NgModule } from '@angular/core';
import { NgxUiLoaderHttpModule } from 'ngx-ui-loader';
@NgModule({
  declarations: [],
  imports: [
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true, exclude: ["api/Auth/refreshToken", "api/ColumnChange/Savewidth?", "api/ColumnChange/getwidth?",
        "api/JobPortalLogin/getAgencyDetail?", "api/BillingClaim/checkClaimCreationProgress?",
        "api/ClientAuthorization/GetAuthorizationServices?","api/DynamicEmployeeForm/updateDynamicEmployeeForm",
        "api/DynamicClientForm/updateDynamicClientForm", 
        "api/ClientAuthorization/getAuthDropForClient?", "api/Employee/GetSuspeciousEmployees","api/Employee/GetLEIEMatchedEmployees","api/ClientAuthorization/getAuthorizationsForVisitSelection"]
    }),
  ],
})

export class PagesModule { }
