export const environment = {
  "production":true,
  "baseUrl":"https://allcareus-api-uat-002-gkh8emdqcgakftcb.centralus-01.azurewebsites.net/",
  "uiVresion":"0.0.024",
  "hosturl":"https://allcareus-api-uat-002-gkh8emdqcgakftcb.centralus-01.azurewebsites.net/",
  "ADP_PayrollReport":"b033d8cf-1501-45df-b72f-a54a048a6b21",
  "AgingDateReportId":"3de5051f-980d-4def-821b-d186408e8722",
  "AgingReportwithDateReportId":"7622aea4-bf7c-4728-8781-6ab1cbcfc880",
  "AgingReportwithoutDateReportId":"e1b72bc5-d1f0-4179-8be9-616878a14125",
  "BatchCreateReportId":"8c2aa99d-0ab0-4689-a271-b7693c471808",
  "BatchViewReportId":"c1a1f293-4f97-4f6a-b458-113119461e0c",
  "BilledClientReportId":"602f8f83-d5ea-4915-9440-580028c5fe91",
  "CheckEFT":"7e73397e-0a5a-4b8d-8363-12bec746631b",
  "ClaimVsPaid":"eed42e76-5606-4c0f-9f32-c618ff5528f9", 
  "ClientBirthDayReportId":"f25aaecf-7da8-426f-9207-6dc4d749d54f",
  "ClientHoursSummaryReportId":"9d03a599-285e-48c3-a870-fd9293716ba1",
  "ClientLabels":"245161c5-1822-4296-901a-3af831db8e5e",
  "Client_EmployeeNotesId":"a9926e91-6fa2-4334-81c8-9b8a7b7130c2",
  "ComingDueAuthorizationChartReportId":"10c39013-a575-4fa3-a983-a9acaac16457",
  "ComingDueAuthorizationReportId":"072ae660-9129-4dae-92b2-acc30e70c49d",
  "EmployeeBirthDayReportId":"e23b5b6a-d21a-4524-b1cf-6f02a1e35d0c",
  "EmployeeHoursSummaryReportId":"9a9b13c1-2fde-4b72-bdce-445400db04e7",
  "EmployeePayRateReportId":"adcd00f6-d2f0-4f9d-b510-54aeaba29258",
  "EmployeePayorIdListReportId":"1a50b5d3-d665-4acc-8c21-9c7f5496f497",
  "EndOfYearReport":"54b5049d-34d7-4387-8536-25e351dcea8b",
  "HousingCaseReportId":"265de9b4-3426-4f6d-a446-7f08c34aac6f",
  "HousingTimesheetReportId":"d2043cb7-f100-4bd0-8130-b35cbf49ac0b",
  "LastDateofServiceReportId":"98382338-16b5-4da5-8ce5-69a91c7e838e",
  "LiveInReportId":"69350af9-f025-463a-a4a8-b27020323707",
  "MissingTimesheetReportId":"ffe96718-4752-4e26-8ab5-0e2368eed991",
  "OpenInvoiceReportId":"9d2acd6d-fc23-4647-8452-6395ec526bc0",
  "PaidPayrollNotBilledReportDetails":"08c3dad2-5f57-47a7-9503-78b507bd2d65",
  "PaidPayrollNotBilledReportSummary":"934029ce-96fb-496c-b629-9dedc3be8aa0",
  "PayrollExcelReportId":"934f88db-d8f4-49b6-809a-8c849f5822b7",
  "PayrollReportId":"f0e50618-263b-49bd-bbdf-d165f3e99953",
  "PowerBIWorkSpace":"438731eb-4697-44cb-987c-9d3a3af6459d",
  "PrivatePayMileageLogReportId":"4dece16e-5051-4bf4-bdb1-977e589975a5",
  "QpEmployeeReportId":"c34be91c-aeaf-41e7-af6d-30e4ed7c9994",
  "QpInfoReportId":"f8401726-aa28-4670-98ab-3592fddfee66",
  "RPReportId":"1d502ab3-ebe4-4473-82f1-ea1409be9477",
  "SpendDown":"3d7eb370-341c-4b6b-8a0d-7fb6a5bb8679",
  "SubmissionReportId":"b300bf89-a126-4805-b1b0-a0fd0820f93d",
  "SupervisionReportId":"b25f7eb0-7a8a-42d7-98db-54b68c879e85",
  "TimesheetReportId":"2b21ea25-0182-4e99-b569-e134be93b7a1",
  "VisitReportId":"86ff8850-4b3e-41d9-bf66-04c92b4046bb",
  "WorkHoursSummaryReportId":"a8f19d8c-079a-4e74-9e42-77a3b59354e9",
  "clienthoursReportID":"9d03a599-285e-48c3-a870-fd9293716ba1",
  "employeeLabels":"9ffbd7ba-2b1e-4053-ad42-1ad67220fd76",
  "inactiveclientReportID":"a8686506-2440-49f9-b364-d184e918a365",
  "EmployeementServiceTimesheetReportId":"86d70170-9059-4884-a44a-0ccab66596da",
  "EmployeementServiceReportId":"f134ab51-5b79-49b8-ad9c-abb914af5bc3",
  "revenue":"fbe6264b-b430-4626-ad62-8cea16b40dd5",
  "EWEReportId": "c0201a09-a366-41e1-8463-f107f0b12780",
  "Paylocity_PayrollReportId":"bf2af8dd-73f1-4472-a583-da0aec3f9c16",
  "DepositReportId":"1869ac12-4ba9-48c3-9a42-d3eb3c91c450"
  }
