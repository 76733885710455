import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { ErrorService } from 'src/app/services/error.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalComponent } from 'src/app/global/global.component';
@Injectable({
  providedIn: 'root'
})
export class UserMenuService {
  constructor(private http: HttpClient, public errorService: ErrorService, public global: GlobalComponent) { }

  logoutSession(value: any): Observable<any> {
    let url = "api/Auth/logout?"
    let params = new URLSearchParams();
    params.append("revokeAllSessions", JSON.stringify(value))
    params.append("refreshToken", this.global.refreshTokenString)
    return this.http.post<any>(url + params, value, { observe: 'response' }).pipe(
      map(data => data.body),
      catchError(this.errorService.handleError)
    )
  }
}