<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        <i class="fa fa-user" style="color:#004290" aria-hidden="true" (click)="clearBoolean()"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div class="user-menu">
            <div class="user-info text-center p-3">
                <img src="/assets/img/allcarehome.png" style="height: 100px;width: 279px;">
            </div>
            <br>
            <label for="userNames" class="ml-4">Username:&nbsp;{{global.logginedUserName}}</label>
            <br>
            <label for="roles" class="ml-4">Role:&nbsp;{{global.globalroleName}}</label>
            <br>
            <label for="roles" class="ml-4">Timezone:&nbsp;{{global.timeZone }}</label>
            <br>
            <label for="roles" class="ml-4">AgencyCurrentTime:&nbsp;<label class="font-red animate-charcter">{{global.agencyCurrentTime }}</label></label>
            <button class="dropdown-item mt-0" (click)="routeToActiveSession()"><i
                    class="fa fa-calendar mr-2"></i>Manage Session</button>
            &nbsp;&nbsp;&nbsp;&nbsp; <input type="checkbox" style="background-color: lightgray;
            width: 17px" [(ngModel)]="islogoutOfAllSessions" class="mt-0" />&nbsp;Revoke All Sessions
            <!-- <br> -->
            <button class="dropdown-item mb-1 mt-0" (click)="logoutSession()"><i class="fa fa-power-off mr-2"></i>Log
                out</button>
        </div>
    </div>
</div>