import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev'

@Injectable()
export class EnvironmentService {
  private http: HttpClient;
  configuration: any;
  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }
  loadConfiguration() {
    this.configuration = environment;
  }
  getConfiguration() {
    return this.configuration;
  }
}
