<div class="modal-header">
    <h4 class="modal-title" style="color: red;">Important !!!</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12">
            <h6>
                Please Change Your System Timezone to Agency Zone and Re-Login!!!
            </h6>
        </div>
    </div>
</div>
<div class="modal-footer" style="justify-content: space-between;">
    <p style="font-style: italic;color: red;"> CAUTIONS : Your Data will not be Retrieved Correctly</p>
    <button type="button" class="btn blue-btn btn-sm" (click)="closeModal()">Okay</button>
</div>