import { Component, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "./app.settings";
import { Settings } from "./app.settings.model";
import { GlobalComponent } from "src/app/global/global.component";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { PWAService } from "./progressivewebservice";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  private onDestroy$: Subject<void> = new Subject<void>();
  public settings: Settings;
  menu: any;
  JobPortalData: any = {};
  menusitems: any = {};
  agencyList: any = {};
  agencydropdown: any = {};
  loginData: any = {};
  agencyData: any = {};
  locationData: any = 0;
  appUserData: any = {};
  refreshData: any = {};
  constructor(
    public appSettings: AppSettings,
    private router: Router,
    public global: GlobalComponent,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    public toasterService: ToastrService,
    public serviceWorkers: PWAService
  ) {
    this.settings = this.appSettings.settings;
    this.appUserData = JSON.parse(localStorage.getItem("ac_login"));
    this.refreshData = JSON.parse(localStorage.getItem("ac_refreshtoken"));
    if (
      this.appUserData != null &&
      this.appUserData != undefined &&
      this.appUserData != ""
    ) {
      this.serviceWorkers.checkForUpdates();
    }
  }

  ngOnInit() {
    this.global.deviceWidth = window.innerWidth;
    //   window.addEventListener('scroll', this.scroll, true);
    this.global.isassignboolean = false;
    let jobSeekerData = localStorage.getItem("ac_jobportaldata");
    let JobportalagencyId = JSON.parse(
      localStorage.getItem("ac_jobportalagencyId")
    );
    if (
      JobportalagencyId != null &&
      JobportalagencyId != undefined &&
      JobportalagencyId != 0
    ) {
      this.global.JobportalagencyId = JobportalagencyId;
    }
    let employeeNewTab = JSON.parse(
      localStorage.getItem("ac_EmployeeNewTabFunctionality")
    );
    if (employeeNewTab != null) {
      this.global.isEmployeeNewTab = employeeNewTab.isEmployeeNewTab;
      this.global.searchEmployeeText = employeeNewTab.EmployeeName.employeename;
      this.global.searchEmployeeStatus = employeeNewTab.EmployeeName.status;
    }
    let clientNewTab = JSON.parse(
      localStorage.getItem("ac_ClientNewTabFunctionality")
    );
    if (clientNewTab != null) {
      this.global.isClientNewTab = clientNewTab.isClientNewTab;
      this.global.searchClientText = clientNewTab.ClientName.clientname;
      this.global.searchClientStatus = clientNewTab.ClientName.status;
    }

    if (this.appUserData != null) {

      this.global.loginType = "AgencyLogin";
      this.global.refreshTokenString = this.refreshData;

      this.global.authtoken = this.appUserData.accessToken;
      this.global
        .refreshToken()
        .then((data) => {

          this.global.scheduleRefresh();
          if(this.global.userID != 0 && this.global.userID != undefined && this.global.userID != null) this.global.getWebsiteUserDetails();
          console.log("timezone")
          this.global.GetTimeZoneDropdown();
          this.global.getEmployeeType();
          this.appUserData = JSON.parse(localStorage.getItem("ac_login"));
          this.global.GeneratingToken(this.appUserData);
          this.getSideMenus();
          // this.global.getOverallAgencyDropDown();
          //All calls (side menu, agency dropdown list, etc..)
        })
        .catch((err) => {
          // this.toasterService.error("Your Session Got Expired, Please Login", 'Session Expired');
          this.global.agencyLogo = null;
          this.global.agencyName = null;
          this.global.agencyPhone = null;
          this.global.globalAgencyId = "";
          this.global.globalLocationId = "";
          this.global.ngselectedLocation = "";
          this.global.ngLocationDropDown = [];
          this.global.userRoleDropDown = [];
          this.global.userSelectedRole = "";
          this.global.ngselectedAgency = "";
          this.global.globalTimezone = "";
          this.global.timeZone = "";
          this.global.timeZoneLOV = [];
          this.global.globalState = "";
          this.global.clearLocalStorage();
          this.router.navigateByUrl("/login"); //Navigated in refreshToken function itself. Just for extra safety
        });
    }
    //..............................sidebars///////////////////////////////////
    this.menusitems = localStorage.getItem("ac_sidebars");
    this.menusitems = JSON.parse(this.menusitems);
    ///..................................ac_agencydetails........................................
    this.agencyList = JSON.parse(localStorage.getItem("ac_agencydetails"));
    //......................................ac_agencyNames........................................
    this.agencydropdown = JSON.parse(localStorage.getItem("ac_agencyNames"));
    //...................................ac_login.................................................
    this.loginData = localStorage.getItem("ac_login");
    this.loginData = JSON.parse(this.loginData);
    this.agencyData = JSON.parse(
      localStorage.getItem("ac_globalAgencydetails")
    );
    if (this.loginData != null) {

      // this.global.GeneratingToken(this.loginData);
      //ac_agencydetails
      if (this.agencyList != null) {
        this.global.getAgencyDetails(this.agencyList);
      }
      //Menu_items//
      if (this.menusitems != null) {
        this.global.AddMenuItems(this.menusitems);
      }
      //agencyData//
      if (this.agencyData != null) {
        this.global.Addagencydata(this.agencyData);
      }
      //agencydropdown
      if (this.agencydropdown != null) {
        this.global.AddAgencyDropdown(this.agencydropdown);
      }
      //locationId
      this.locationData = JSON.parse(localStorage.getItem("ac_locationId"));
      this.global.ngselectedLocation = JSON.parse(
        localStorage.getItem("ac_locationId")
      );
      if (this.global.ngselectedLocation != null) {
        this.global.ngselectedLocation =
          this.global.ngselectedLocation == 0
            ? ""
            : this.global.ngselectedLocation.toString();
      }
      this.global.globalLocationId = this.locationData;
      if (this.global.ngselectedLocation == 0) {
        this.global.ngselectedLocation = null;
      }
      // this.getUserLocationDropDown(this.agencyList.userId);
      // roles
      if (this.agencyList.userId == 0) {
        this.global.roleId = this.agencyList.roleId;
      }
      if (
        this.agencyList.userId != null &&
        this.agencyList.userId != undefined &&
        this.agencyList.userId != 0
      ) {
        // this.getuserDropDown(this.agencyList.userId)
        this.global.roleId = localStorage.getItem("ac_role");
        this.global.userSelectedRole = localStorage.getItem("ac_role");
      }
      this.router.navigateByUrl("/splash");
    } else if (jobSeekerData != null) {
      this.global.loginType = "JobPortalLogin";
      this.JobPortalData = localStorage.getItem("ac_jobportaldata");
      this.JobPortalData = JSON.parse(this.JobPortalData);
      if (this.JobPortalData != null) {
        this.global.AddJobPortaldata(this.JobPortalData);
      }
      this.router.navigate(["/job-portal/dsite/job-seeker"]);
    } else {
      let url = window.location.href;

      if (!url.includes("job-portal")) {
        this.router.navigateByUrl("/login");
      } else {
        let urlpath = url.split("#");
        this.router.navigateByUrl(urlpath[1]);
      }
    }
  }
  getSideMenus() {
    var url = "api/Auth/getSideMenus?";
    this.http.get(url).subscribe(
      (data: any) => {
        let role = this.global.roleId != null ? this.global.roleId.toLocaleUpperCase() : "";
        let splittingRole=role.split('?')[0];
        if (splittingRole == "SYSTEM ADMINISTRATOR") {
          let settingRole =
          {
            "title": "Settings",
            "icon": "cog",
            "link": "/setting",
            "children": null
          }
          data.push(settingRole)
        }
        this.global.AddMenuItems(data);
        localStorage.setItem("ac_sidebars", JSON.stringify(data));
      },
      (err: any) => {
        if (err) {
        }
      }
    );

    if (
      this.agencyList.userId != null &&
      this.agencyList.userId != undefined &&
      this.agencyList.userId != 0
    ) {
      this.getuserDropDown(this.agencyList.userId);
    }
  }

  scroll = (event): void => {
    this.ref.detach();
    setInterval(() => {
      this.ref.detectChanges();
    }, 10);
  };
  ngAfterViewInit() {
    document.getElementById("preloader").classList.add("hide");
  }
  getuserDropDown(id) {
    var url = "api/User/Getuserroledropdown?";
    let myParams = new URLSearchParams();
    myParams.append("userid", id);
    this.http
      .get(url + myParams)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        this.global.userRoleDropDown = data;
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
