import { Injectable } from '@angular/core';
import { GlobalComponent } from './global/global.component';
import { HttpHandler, HttpRequest, HttpEvent, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
@Injectable()
export class AuthService {
  url: string;
  constructor(public global: GlobalComponent, private http: HttpClient, private EnvironmentService: EnvironmentService) {
    let configuration = EnvironmentService.getConfiguration();
    this.url = configuration.baseUrl;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {
    let agencyId: any;
    if (this.global.loginType == "JobPortalLogin") {
      agencyId = ((this.global.JobportalagencyId == null || this.global.JobportalagencyId == 0 || this.global.JobportalagencyId == undefined) ? 0 : this.global.JobportalagencyId)
    }
    else {
      agencyId = ((this.global.globalAgencyId == null || this.global.globalAgencyId == 0 || this.global.globalAgencyId == undefined) ? 0 : this.global.globalAgencyId)
    }
    request = request.clone({
      url: this.url + request.url,
      setHeaders: {
        'Authorization': `Bearer ` + this.global.authtoken,
        'AgencyId': agencyId.toString(),
        "TimeZone": ((this.global.globalTimezone != undefined && this.global.globalTimezone != null && this.global.globalTimezone != "") ? (this.global.globalTimezone) : "")

      },
      
      withCredentials: true
    });
    return next.handle(request);
  }


}
