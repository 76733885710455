import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/errors/not-found/not-found.component";
import { AuthGuard } from "./authorization.guard";
export const routes: Routes = [
  {
    path: "",
    component: PagesComponent, 
    children: [
      {
        path: "employee",
        loadChildren: () =>
          import("./pages/employee-parent/employee-parent.module").then(
            (m) => m.EmployeeParentModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/employee" ,notrequiredrouter:false },
      },

      {
        path: "timesheetverification",
        loadChildren: () =>
          import(
            "./pages/timesheetverification/timesheetverification.module"
          ).then((m) => m.TimesheetverificationModule),
        canActivate: [AuthGuard],
        data: { permission: "/timesheetverification",notrequiredrouter:false },
      },

      {
        path: "claims",
        loadChildren: () =>
          import("./pages/claims/claims.module").then((m) => m.ClaimsModule),
        canActivate: [AuthGuard],
        data: { permission: "/claims",notrequiredrouter:false  },
      },

      {
        path: "agency",
        loadChildren: () =>
          import("./pages/agency/agency.module").then((m) => m.AgencyModule),
        canActivate: [AuthGuard],
        data: { permission: "/agency" ,notrequiredrouter:false },
      },

      {
        path: "setting",
        loadChildren: () =>
          import("./pages/agency-settings/agency-settings.module").then(
            (m) => m.AgencySettingsModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/setting",notrequiredrouter:false  },
      },
      {
        path: "agencyroles",
        loadChildren: () =>
          import("./pages/agencypermission/agencypermission.module").then(
            (m) => m.AgencypermissionModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/agencyroles" ,notrequiredrouter:false },
      },

      {
        path: "agencyreport",
        loadChildren: () =>
          import("./pages/agencyreport/agencyreport.module").then(
            (agencyRoles) => agencyRoles.AgencyreportModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/agencyreport" ,notrequiredrouter:false },
      },
    

      {
        path: "calendarschedule",
        loadChildren: () =>
          import("./pages/calendar-schedule/calendar-schedule.module").then(
            (m) => m.CalendarScheduleModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/calendarschedule"  ,notrequiredrouter:false},
      },

      {
        path: "care-coordinator",
        loadChildren: () =>
          import("./pages/care-coordinator/care-coordinator.module").then(
            (m) => m.CareCoordinatorModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/care-coordinator" ,notrequiredrouter:true },
      },

      {
        path: "case-manager",
        loadChildren: () =>
          import("./pages/case-manager/case-manager.module").then(
            (m) => m.CaseManagerModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/case-manager"  ,notrequiredrouter:true},
      },

      {
        path: "client",
        loadChildren: () =>
          import("./pages/client-parent/client-parent.module").then(
            (m) => m.ClientParentModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/client"  ,notrequiredrouter:false},
      },

      {
        path: "clearing-house",
        loadChildren: () =>
          import("./pages/clearing-house/clearing-house.module").then(
            (m) => m.ClearingHouseModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/clearing-house"  ,notrequiredrouter:false},
      },

      {
        path: "client-intake-form",
        loadChildren: () =>
          import(
            "./pages/prospective-client-intakeform/prospective-client-intakeform.module"
          ).then((m) => m.ProspectiveClientIntakeformModule),
        canActivate: [AuthGuard],
        data: { permission: "/client-intake-form" ,notrequiredrouter:false},
      },

      {
        path: "client-reports",
        loadChildren: () =>
          import("./pages/client-report/client-report.module").then(
            (m) => m.ClientReportModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/client-reports" ,notrequiredrouter:true},
      },

      {
        path: "dischargecode",
        loadChildren: () =>
          import("./pages/discharge-codes/discharge-codes.module").then(
            (m) => m.DischargeCodesModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/dischargecode" ,notrequiredrouter:true},
      },

       {
        path: "qp-info",
        loadChildren: () =>
          import("./pages/qp-info/qp-info.module").then(
            (m) => m.QpInfoModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/qp-info" ,notrequiredrouter:true},
      },

      {
        path: "payroll",
        loadChildren: () =>
          import("./pages/payroll/payroll.module").then(
            (m) => m.PayrollModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/payroll",notrequiredrouter:false },
      },

      {
        path: "timesheets",
        loadChildren: () =>
          import("./pages/timesheet/timesheet.module").then(
            (m) => m.TimesheetModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/timesheets" ,notrequiredrouter:false},
      },

      {
        path: "eligibility",
        loadChildren: () =>
          import("./pages/eligibility270/eligibility270.module").then(
            (m) => m.Eligibility270Module
          ),
        canActivate: [AuthGuard],
        data: { permission: "/eligibility" ,notrequiredrouter:false},
      },

      {
        path: "employee-report",
        loadChildren: () =>
          import("./pages/employee-report/employee-report.module").then(
            (m) => m.EmployeeReportModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/employee-report",notrequiredrouter:true },
      },

      
      {
        path: "schedule",
        loadChildren: () =>
          import("./pages/schedule/schedule.module").then(
            (m) => m.ScheduleModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/schedule" ,notrequiredrouter:false},
      },
     

      {
        path: "qptimesheet",
        loadChildren: () =>
          import(
            "./pages/qualifiedprofessional-timesheet/qualifiedprofessional-timesheet.module"
          ).then((QPs) => QPs.QualifiedprofessionalTimesheetModule),
        canActivate: [AuthGuard],
        data: { permission: "/qptimesheet",notrequiredrouter:false },
      },
      {
        path: "group-payor",
        loadChildren: () =>
          import("./pages/group-payor/group-payor.module").then(
            (m) => m.GroupPayorModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/group-payor",notrequiredrouter:false },
      },

      {
        path: "group-payor-service",
        loadChildren: () =>
          import("./pages/group-payor-service/group-payor-service.module").then(
            (m) => m.GroupPayorServiceModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/group-payor-service" ,notrequiredrouter:false},
      },

      {
        path: "icd10",
        loadChildren: () =>
          import("./pages/icd10/icd10.module").then((m) => m.ICD10Module),
        canActivate: [AuthGuard],
        data: { permission: "/icd10",notrequiredrouter:true },
      },

      {
        path: "import",
        loadChildren: () =>
          import("./pages/import/import.module").then((m) => m.ImportModule),
        canActivate: [AuthGuard],
        data: { permission: "/import",notrequiredrouter:false },
      },

      {
        path: "list",
        loadChildren: () =>
          import("./pages/list/list.module").then((m) => m.ListModule),
        canActivate: [AuthGuard],
        data: { permission: "/list" ,notrequiredrouter:false},
      },

     

      {
        path: "oig",
        loadChildren: () =>
          import("./pages/oig/oig.module").then((m) => m.OigModule),
        canActivate: [AuthGuard],
        data: { permission: "/oig",notrequiredrouter:true },
      },

      {
        path: "zip-codes",
        loadChildren: () =>
          import("./pages/zip-code/zip-code.module").then(
            (m) => m.ZipCodeModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/zip-codes",notrequiredrouter:true },
      },

      {
        path: "manage-service",
        loadChildren: () =>
          import("./pages/manage/manage.module").then((m) => m.ManageModule),
        canActivate: [AuthGuard],
        data: { permission: "/manage-service" ,notrequiredrouter:false},
      },

      {
        path: "lov",
        loadChildren: () =>
          import("./pages/lov/lov.module").then(
            (m) => m.LovModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/lov",notrequiredrouter:false },
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import(
            "./pages/dashboard/dashboard.module"
          ).then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { permission: "/dashboard" ,notrequiredrouter:false},
      },
      {
        path: "process-management",
        loadChildren: () =>
          import("./pages/process-management/process-management.module").then(
            (m) => m.ProcessManagementModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/process-management" ,notrequiredrouter:false},
      },
      {
        path: "clientemployeecontact",
        loadChildren: () =>
          import(
            "./pages/client-employee-contact/client-employee-contact.module"
          ).then((m) => m.ClientEmployeeContactModule),
        canActivate: [AuthGuard],
        data: { permission: "/clientemployeecontact",notrequiredrouter:false },
      },
      {
        path: "menu",
        loadChildren: () =>
          import("./pages/menus/menus.module").then((m) => m.MenuModule),
        canActivate: [AuthGuard],
        data: { permission: "/menu" ,notrequiredrouter:false},
      },

      {
        path: "reports",
        loadChildren: () =>
          import("./pages/reports/reports.module").then((m) => m.ReportsModule),
        canActivate: [AuthGuard],
        data: { permission: "/reports" ,notrequiredrouter:false},
      },

      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        data: { permission: "/users" ,notrequiredrouter:false},
      },

      {
        path: "service-evaluation",
        loadChildren: () =>
          import("./pages/service-evaluation/service-evaluation.module").then(
            (m) => m.SerivceEvaluationModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/service-evaluation" ,notrequiredrouter:false },
      },

      {
        path: "roles",
        loadChildren: () =>
          import("./pages/roles/roles.module").then((m) => m.RolesModule),
        canActivate: [AuthGuard],
        data: { permission: "/roles" ,notrequiredrouter:false},
      },
      {
        path: "location-billing",
        loadChildren: () =>
          import("./pages/location-billing/location-billing.module").then(
            (m) => m.LocationBillingModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/location-billing",notrequiredrouter:false },
      },

      {
        path: "privatepaymileage",
        loadChildren: () =>
          import("./pages/private-pay-mileage/private-pay-mileage.module").then(
            (m) => m.PrivatePayMileageModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/privatepaymileage",notrequiredrouter:false },
      },

      {
        path: "location-status",
        loadChildren: () =>
          import("./pages/location-status/location-status.module").then(
            (m) => m.LocationStatusModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/location-status",notrequiredrouter:false },
      },

      {
        path: "relationship",
        loadChildren: () =>
          import(
            "./pages/relationship/relationship.module"
          ).then((m) => m.RelationshipModule),
        canActivate: [AuthGuard],
        data: { permission: "/relationship" ,notrequiredrouter:false},
      },

      {
        path: "holidays",
        loadChildren: () =>
          import("./pages/holiday/holiday.module").then((m) => m.HolidayModule),
        canActivate: [AuthGuard],
        data: { permission: "/holidays",notrequiredrouter:false },
      },

      {
        path: "employee-onboarding-list",
        loadChildren: () =>
          import(
            "./pages/employee-onboarding-list/employee-onboarding-list.module"
          ).then((m) => m.EmployeeOnboardListModule),
        canActivate: [AuthGuard],
        data: { permission: "/employee-onboarding-list" ,notrequiredrouter:false},
      },

      {
        path: "employee-onboarding",
        loadChildren: () =>
          import("./pages/employee-onboarding/employee-onboarding.module").then(
            (m) => m.employeeOnboardingModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/employee-onboarding",notrequiredrouter:true },
      },

      {
        path: "active-session",
        loadChildren: () =>
          import("./pages/active-session/active-session.module").then(
            (m) => m.ActiveSessionModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/active-session",notrequiredrouter:true },
      },

      {
        path: "housingstabilization",
        loadChildren: () =>
          import(
            "./pages/hs-es/hs-es.module"
          ).then((m) => m.HSESModule),
        canActivate: [AuthGuard],
        data: { permission: "/housingstabilization",notrequiredrouter:false },
      },

      {
        path: "transactionlogs",
        loadChildren: () =>
          import("./pages/transaction-logs/transaction-logs.module").then(
            (m) => m.TransactionLogsModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/transactionlogs",notrequiredrouter:false },
      },

      {
        path: "caregiverlogs",
        loadChildren: () =>
          import("./pages/caregiver-logs/caregiver-logs.module").then(
            (m) => m.CaregiverLogsModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/caregiverlogs",notrequiredrouter:false },
      },

      {
        path: "master",
        loadChildren: () =>
          import("./pages/master/master.module").then((m) => m.MasterModule),
        canActivate: [AuthGuard],
        data: { permission: "/master",notrequiredrouter:true  },
      },

      {
        path: "splash",
        loadChildren: () =>
          import("./pages/splash/splash.module").then((m) => m.SplashModule),
        canActivate: [AuthGuard],
        data: { permission: "/splash",notrequiredrouter:true },
      },

      {
        path: "visits",
        loadChildren: () =>
          import("./pages/visits/visits.module").then((m) => m.VisitsModule),
        canActivate: [AuthGuard],
        data: { permission: "/visits",notrequiredrouter:false },
      },

      {
        path: "dynamicFormTemplate",
        loadChildren: () =>
          import("./pages/dynamicformtemplate/dynamicformtemplate.module").then(
            (m) => m.DynamicFormTemplateModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/dynamicFormTemplate",notrequiredrouter:false },
      },

      {
        path: "sftplogs",
        loadChildren: () =>
          import("./pages/sftplogs/sftplogs.module").then(
            (m) => m.SFTPLogsModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/sftplogs",notrequiredrouter:false},
      },

      {
        path: "payrateTemplate",
        loadChildren: () =>
          import("./pages/payrate-template/payrate-template.module").then(
            (m) => m.PatrateTemplateModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/payrateTemplate",notrequiredrouter:false },
      },
      {
        path: "admintime",
        loadChildren: () =>
          import("./pages/admin-time/admin-time.module").then(
            (m) => m.AdminTimeModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/admintime",notrequiredrouter:false },
      },
      {
        path: "housing-coordinator",
        loadChildren: () =>
          import("./pages/housing-coordinator/housing-coordinator.module").then(
            (m) => m.HousingCoordinatorModule
          ),
        canActivate: [AuthGuard],
        data: { permission: "/housing-coordinator",notrequiredrouter:true },
      },
      {
        path: "unauthorizedusers",
        loadChildren: () =>
          import("./pages/unauthorized-users/unauthorized-users.module").then(
            (m) => m.UnAuthorizedUsersModule
          ),
          canActivate: [AuthGuard],
        data: { permission: "/unauthorizedusers",notrequiredrouter:true },
      },
      { 
        path: "employmentservices",
        loadChildren: () =>
          import(
            "./pages/employment-services/employment-services.module"
          ).then((m) => m.EmploymentServicesModule),
        canActivate: [AuthGuard],
        data: { permission: "/housingstabilization",notrequiredrouter:false },
      },
      {
        path:"userlogs",
        loadChildren:()=>
        import("./pages/user-logs/user-logs.module").then((m)=>m.UserLogsModule),
        canActivate: [AuthGuard],
            data: { permission: "/userlogs" ,notrequiredrouter:false },
      },
      // {
      //   path: "adultdaycare",
      //   loadChildren: () =>
      //     import("./pages/adult-day-care/adult-day-care.module").then(
      //       (m) => m.AdultDayCareModule
      //     ),
      //   data: { breadcrumb: "adult-day-care" },
      // },
    ],
  },

  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/forgotpassword/forgotpassword.module").then(
        (m) => m.ForgotpasswordModule
      ),
  },

  {
    path: "reset-password",
    loadChildren: () =>
      import("./pages/resetpassword/resetpassword.module").then(
        (m) => m.ResetpasswordModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "password",
    loadChildren: () =>
      import("./pages/password/password.module").then((m) => m.PasswordModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "job-portal/:agencyCode",
    loadChildren: () =>
      import("./jobportal/jobportal.module").then((m) => m.JobportalModule),
  },


  { path: "**", component: NotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule],
})
export class AppRoutingModule {}
